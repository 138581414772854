/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dashboard.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./dashboard.component";
import * as i3 from "../../core/auth/auth.service";
var styles_AdminDashboardComponent = [i0.styles];
var RenderType_AdminDashboardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdminDashboardComponent, data: {} });
export { RenderType_AdminDashboardComponent as RenderType_AdminDashboardComponent };
export function View_AdminDashboardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "admin-dashboard"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "top-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Dashboard"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "dashboard-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "a", [["href", "https://github.com/ajspera/auditor-admin/issues/5"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Go here to make suggestions for this page"]))], null, null); }
export function View_AdminDashboardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "admin-dashboard", [], null, null, null, View_AdminDashboardComponent_0, RenderType_AdminDashboardComponent)), i1.ɵdid(1, 114688, null, 0, i2.AdminDashboardComponent, [i3.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdminDashboardComponentNgFactory = i1.ɵccf("admin-dashboard", i2.AdminDashboardComponent, View_AdminDashboardComponent_Host_0, {}, {}, []);
export { AdminDashboardComponentNgFactory as AdminDashboardComponentNgFactory };
