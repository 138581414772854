<div class="admin-clients">
  <div class="top-bar borderless clearfix">
  </div>

  <div class="dashboard-content">
    <div class="actions">
      <button (click)="addClient()" type="button">
        + new client
      </button>
    </div>
    <admin-clients-list [clients]="clients"></admin-clients-list>
  </div>
</div>

<bs-modal #createFormModal>
  <admin-client-form [(client)]="newClient" (saved)="onClientCreate($event)" (canceled)="onClientCreateCancel()"></admin-client-form>
</bs-modal>
