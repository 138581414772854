import { Component, Input, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';

import { License } from '../../core/license/license';
import { LicenseService } from '../../core/license/license.service';

@Component({
  selector: 'admin-licenses-list',
  templateUrl: './licenses-list.component.html',
  styleUrls:  ['./licenses-list.component.less']
})
export class AdminLicensesListComponent {
  @Input() licenses: License[];

  constructor(
  ) { }

}

@Component({
  selector: 'admin-licenses',
  templateUrl: './licenses.component.html',
  // styleUrls:  ['app/admin-panel/licenses/licenses.component.css']
})
export class AdminLicensesComponent implements OnInit {

  licenses: License[];
  newLicense: License = new License();

  constructor(
    private licenseService: LicenseService
  ) { }

  ngOnInit() {
    this.getLicenses();
  }

  getLicenses() {
    this.licenseService.getCollection().subscribe(licenses => this.licenses = licenses);
  }

  addLicense(license) {
    this.licenses.unshift(license);
  }
}
