/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/forms";
import * as i3 from "./auto-complete-base";
var styles_AutoCompleteBase = [];
var RenderType_AutoCompleteBase = i0.ɵcrt({ encapsulation: 2, styles: styles_AutoCompleteBase, data: {} });
export { RenderType_AutoCompleteBase as RenderType_AutoCompleteBase };
function View_AutoCompleteBase_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "auto-complete-results"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "auto-complete-loading"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" loading "]))], null, null); }
function View_AutoCompleteBase_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "button", [["type", "button"]], [[2, "active", null]], [[null, "mouseup"], [null, "mousedown"], [null, "mouseover"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseup" === en)) {
        var pd_0 = (_co.applySelection(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } if (("mousedown" === en)) {
        _co.clickingOption = true;
        i0.ɵnov(_v.parent.parent, 2).focus();
        var pd_1 = ((_co.clickingOption = false) !== false);
        ad = (pd_1 && ad);
    } if (("mouseover" === en)) {
        var pd_2 = ((_co.focusedIndex = _v.context.index) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.focusedIndex == _v.context.index); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.formatButtonText(_v.context.$implicit); _ck(_v, 1, 0, currVal_1); }); }
function View_AutoCompleteBase_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "button", [["type", "button"]], [[2, "active", null]], [[null, "mouseup"], [null, "mousedown"], [null, "mouseover"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseup" === en)) {
        var pd_0 = (_co.applySelection(_co.addNewFuncIndex) !== false);
        ad = (pd_0 && ad);
    } if (("mousedown" === en)) {
        _co.clickingOption = true;
        i0.ɵnov(_v.parent.parent, 2).focus();
        var pd_1 = ((_co.clickingOption = false) !== false);
        ad = (pd_1 && ad);
    } if (("mouseover" === en)) {
        var pd_2 = ((_co.focusedIndex = _co.addNewFuncIndex) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" add new "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.focusedIndex == _co.addNewFuncIndex); _ck(_v, 0, 0, currVal_0); }); }
function View_AutoCompleteBase_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "no-results"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" No results "]))], null, null); }
function View_AutoCompleteBase_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "auto-complete-results"], ["style", ""]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" No Results Found "]))], null, null); }
function View_AutoCompleteBase_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["resultComponent", 1]], null, 6, "div", [["class", "auto-complete-results"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AutoCompleteBase_3)), i0.ɵdid(3, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AutoCompleteBase_4)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AutoCompleteBase_5)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AutoCompleteBase_6)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.autoCompleteResults; _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.addNewFuncIndex != null); _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.autoCompleteNoResults; _ck(_v, 7, 0, currVal_3); var currVal_4 = ((_co.autoCompleteNoResults == true) && !_co.addNewFunc); _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.showDrop || !_co.isFocused); _ck(_v, 1, 0, currVal_0); }); }
export function View_AutoCompleteBase_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { resultComponent: 0 }), i0.ɵqud(671088640, 2, { inputElem: 0 }), (_l()(), i0.ɵeld(2, 0, [[2, 0], ["inputElem", 1]], null, 5, "input", [["class", "form-control"], ["name", "typedIn"], ["placeholder", "start typing and select from list"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "keyup"], [null, "keydown"], [null, "focus"], [null, "blur"], [null, "input"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 3).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 3)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 3)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.typedIn = $event) !== false);
        ad = (pd_4 && ad);
    } if (("keyup" === en)) {
        var pd_5 = (_co.inputKeyUp($event) !== false);
        ad = (pd_5 && ad);
    } if (("keydown" === en)) {
        var pd_6 = (_co.inputKeyDown($event) !== false);
        ad = (pd_6 && ad);
    } if (("focus" === en)) {
        _co.isFocused = true;
        var pd_7 = (_co.setupAndDoSearch() !== false);
        ad = (pd_7 && ad);
    } if (("blur" === en)) {
        var pd_8 = ((_co.isFocused = false) !== false);
        ad = (pd_8 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 16384, null, 0, i2.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i0.ɵdid(5, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i0.ɵdid(7, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i0.ɵeld(8, 0, null, null, 4, "div", [["class", "auto-complete-container"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AutoCompleteBase_1)), i0.ɵdid(10, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AutoCompleteBase_2)), i0.ɵdid(12, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = "typedIn"; var currVal_8 = _co.typedIn; _ck(_v, 5, 0, currVal_7, currVal_8); var currVal_9 = (_co.autoCompleteLoading == true); _ck(_v, 10, 0, currVal_9); var currVal_10 = (_co.autoCompleteLoading != true); _ck(_v, 12, 0, currVal_10); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 7).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 7).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 7).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 7).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 7).ngClassValid; var currVal_5 = i0.ɵnov(_v, 7).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 7).ngClassPending; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_AutoCompleteBase_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "auto-complete", [], null, null, null, View_AutoCompleteBase_0, RenderType_AutoCompleteBase)), i0.ɵdid(1, 573440, null, 0, i3.AutoCompleteBase, [], null, null)], null, null); }
var AutoCompleteBaseNgFactory = i0.ɵccf("auto-complete", i3.AutoCompleteBase, View_AutoCompleteBase_Host_0, { autoCompleteApiService: "autoCompleteApiService", item: "item", formatTextFunc: "formatTextFunc", queryTimeoutDuration: "queryTimeoutDuration" }, { itemChange: "itemChange", addNewFunc: "addNewFunc", loadingChange: "loadingChange" }, []);
export { AutoCompleteBaseNgFactory as AutoCompleteBaseNgFactory };
