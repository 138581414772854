<auto-complete
  [autoCompleteApiService]="autoCompleteApiService"
  (loadingChange)="loading = $event; loadingChange.emit($event)"
  [formatTextFunc]="formatButtonText"
  [(item)]="city"
  (addNewFunc)="createItem($event)"
  (itemChange)="onSelect($event)">
</auto-complete>

<bs-modal #createFormModal>
  <city-form [(city)]="newCity" (saved)="onCityCreate($event)" (canceled)="onCityCreateCancel()"></city-form>
</bs-modal>
