<div class="admin-licenses">
  <div class="top-bar borderless clearfix">
  </div>

  <div class="dashboard-content">
    <div class="actions">
      
    </div>
    <admin-licenses-list [licenses]="licenses"></admin-licenses-list>
  </div>
</div>
