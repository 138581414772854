import { Injectable } from '@angular/core';

import { Observable, ReplaySubject, throwError } from 'rxjs';

import { ApiService } from '../api.service';

import { User } from '../user/user';
import { AuthSession } from './auth-session';

import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class AuthService {
  private loginUrl = '/login';
  private logoutUrl = '/logout';
  private currentSession: AuthSession = null;
  private loginReady: boolean;
  private currentUserSubject: ReplaySubject<User> = new ReplaySubject(1);
  private loginReadySubject: ReplaySubject<boolean> = new ReplaySubject(1);
  private loginReady$: Observable<boolean> = this.loginReadySubject.asObservable();

  loginRedirect: string;
  currentUser: User = null;
  docusignRecipients: DocusignEnvelope[] = null;
  currentUser$: Observable<User> = this.currentUserSubject.asObservable();
  trellisAccess: boolean = null;


  constructor(
    private apiService: ApiService,
    private router: Router
  ) {

    const credCookie = localStorage.getItem('creds');
    if (credCookie) {
      const credObj = JSON.parse(credCookie);
      this.currentSession = {
        user_id: credObj.user_id,
        email: credObj.email,
        auth_token: credObj.auth_token
      };
      this.loadApiHeaders();
      this.login().subscribe();
    } else {
      this.loginReady = true;
      this.loginReadySubject.next(true);
    }
  }

  checkLogin(): Observable<boolean> {
    return new Observable(checkObserver => {
      if (this.loginReady) {
        if (this.currentUser) {
          checkObserver.next(true);
        } else {
          checkObserver.next(false);
        }
        checkObserver.complete();
      } else {
        this.loginReady$.subscribe((ready) => {
            if (ready) {
              if (this.currentUser) {
                checkObserver.next(true);
              } else {
                checkObserver.next(false);
              }
              checkObserver.complete();
            }
        });
      }
    });
  }

  private loadApiHeaders() {
    this.apiService.addAuthHeaders(this.currentSession.email, this.currentSession.auth_token);
  }

  login(email?: string, password?: string): Observable<User> {
    this.loginReady = false;
    this.loginReadySubject.next(false);

    let body = {};
    if(email !== undefined) {
      this.resetAuth();
      body = {email, password};
    }
    return this.apiService.post(this.loginUrl, body).pipe(map((res) => {
      return this.handleLogin(res);
    }), catchError((error) => {
      return this.handleLoginError(error);
    }));
  }

  private handleLogin(res: AuthPayload): User {
    const body = res;
    this.currentUser = body.user;
    this.docusignRecipients = body.docusign_recipients;
    this.trellisAccess = body.trellis_access;

    this.currentSession = {
      user_id: this.currentUser.id,
      email: this.currentUser.email,
      auth_token: body.token
    };

    this.loadApiHeaders();
    localStorage.setItem('creds', JSON.stringify(this.currentSession));

    this.currentUserSubject.next(this.currentUser);
    this.loginReady = true;
    this.loginReadySubject.next(true);

    if (this.loginRedirect) {
      if (['/login', '/logout'].indexOf(this.loginRedirect) === -1) {
        this.router.navigate([this.loginRedirect]);
      } else {
        this.router.navigate(['/']);
      }
    }

    return this.currentUser;
  }
  private handleLoginError(error: any) {
    let errors: any = {server_error: 'Server error'};
    if (error.status === 401) {
      this.resetAuth();
      errors = {login_incorrect: 'Login incorrect'};
    }
    this.loginReady = true;
    this.loginReadySubject.next(true);
    return throwError(errors);
  }
  resetAuth() {
    this.apiService.removeAuthHeaders();
    this.currentSession = null;
    this.currentUser = null;
    this.docusignRecipients = null;
    this.trellisAccess = null;
    this.currentUserSubject.next(this.currentUser);
  }
  logout() {
    return this.apiService.post(this.logoutUrl, {})
      .subscribe(res => this.handleLogout(res), res => this.handleLogout(res));
  }
  private handleLogout(res) {
      this.resetAuth();
  }


}

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private authService: AuthService, private router: Router) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const observer = this.authService.checkLogin();
    observer.subscribe((loggedin) => {
      if (!loggedin) {
        this.router.navigate(['/login'], { skipLocationChange: true });
        this.authService.loginRedirect = state.url;
      } else if (!this.authService.currentUser.is_admin) {
        this.router.navigate(['/logout']);
        alert('you do not have admin privileges.');
      }
    });

    return observer;
  }
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(route, state);
  }
}

export interface DocusignSigner {
  status: string;
  roleName: string;
}

export interface DocusignEnvelope {
  envelopId: string;
  signers: DocusignSigner[];
}

export interface AuthPayload {
  docusign_recipients: DocusignEnvelope[];
  trellis_access: boolean;
  user: User;
  token: string;
}
