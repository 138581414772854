import { AuditType } from '../audit-type/audit-type';
import { LicenseType } from '../license-type/license-type';

export class License {
  id: number;
  client_id: number;
  license_number: string;
  title: string;
  audit_type_id: number;
  audit_type: AuditType;
  license_type_id: number;
  license_type: LicenseType;
  address: string;
  address2: string;
  state: string;
  country: string;
  city: string;
  county: string;
  zip: string;
  braintree_subscription_id: string;
  paid_through_date: Date;
  next_billing_date: Date;
  next_bill_amount: Date;
  braintree_payment_method_token: string;
  subscription_exempt: boolean;
  subscription_status: string;
}
