<div *ngIf="license" class="admin-license">
  <div class="top-bar clearfix">
    <div class="actions">
      <button (click)="editLicense()" type="button">
        edit
      </button>
    </div>
    <h1>{{license.title}}</h1>
    <h2><span class="smaller">License Type:</span> {{license.audit_type ? license.audit_type.title : 'not set'}}</h2>
  </div>

  <div class="dashboard-content">
    <div class="info clearfix">
      <div class="col-xs-6">
        <div class="location-cont">
          <a *ngIf="license.client_id" routerLink="/admin/client/{{license.client_id}}">Go To Client</a>
          <div><strong>Lic#:</strong> {{license.license_number}}</div>
          <div>{{ license.address }}</div>
          <div>{{ license.address2 }}</div>
          <div><strong>City:</strong> {{ license.city }}</div>
          <div><strong>County:</strong> {{ license.county }}</div>
          <div><strong>State:</strong> {{ license.state }}</div>
          <div><strong>Zip:</strong> {{ license.zip }}</div>
        </div>
      </div>
      <div class="col-xs-6">
        <div><strong>Subscription Exempt:</strong> {{license.subscription_exempt ? 'yes' : 'no'}}</div>
        <div><strong>Subscription Status:</strong> {{license.subscription_status ? license.subscription_status.replace('_', ' ') : 'N/A'}}</div>
        <div><strong>Braintree Subscription id:</strong> {{license.braintree_subscription_id ? license.braintree_subscription_id : 'none'}}</div>
        <div><strong>Braintree Payment Method token:</strong> {{license.braintree_payment_method_token ? license.braintree_payment_method_token : 'none'}}</div>
        <div><strong>Paid Through:</strong> {{license.paid_through_date ? license.paid_through_date : 'N/A'}}</div>
        <div><strong>Next Billing Date:</strong> {{license.next_billing_date ? license.next_billing_date : 'N/A'}}</div>
        <div><strong>Billing Amount:</strong> {{license.next_bill_amount ? license.next_bill_amount : 'N/A'}}</div>
      </div>
    </div>

  </div>

  <bs-modal #editFormModal>
    <admin-license-form [license]="licenseEdit" (saved)="onLicenseSave($event)" (canceled)="editFormModal.close()"></admin-license-form>
  </bs-modal>

</div>
<div *ngIf="!license" class="top-bar">
  <span [hidden]="!licenseIsLoading">loading...</span>
  <span *ngFor="let error of licenseLoadErrors | mapToIterable">{{ error.val }} <br></span>
</div>
