/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./clients-list.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./clients.component";
import * as i5 from "../../../../node_modules/ng2-bs3-modal/modal/modal.ngfactory";
import * as i6 from "ng2-bs3-modal/modal/modal";
import * as i7 from "ng2-bs3-modal/modal/modal-service";
import * as i8 from "./client-form.component.ngfactory";
import * as i9 from "./client-form.component";
import * as i10 from "../../core/client/client.service";
var styles_AdminClientsListComponent = [i0.styles];
var RenderType_AdminClientsListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdminClientsListComponent, data: {} });
export { RenderType_AdminClientsListComponent as RenderType_AdminClientsListComponent };
function View_AdminClientsListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "a", [["class", "tabular-row"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "tabular-cell name"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "tabular-cell state"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "tabular-cell created"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "]))], function (_ck, _v) { var currVal_2 = i1.ɵinlineInterpolate(1, "/admin/client/", _v.context.$implicit.id, ""); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit.client_name; _ck(_v, 3, 0, currVal_3); var currVal_4 = _v.context.$implicit.state; _ck(_v, 5, 0, currVal_4); var currVal_5 = _v.context.$implicit.created_at; _ck(_v, 7, 0, currVal_5); }); }
export function View_AdminClientsListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "tabular"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "tabular-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "tabular-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "tabular-head name"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" client name "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "tabular-head state"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" state "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "tabular-head created"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" created "])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "tabular-body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdminClientsListComponent_1)), i1.ɵdid(11, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.clients; _ck(_v, 11, 0, currVal_0); }, null); }
export function View_AdminClientsListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "admin-clients-list", [], null, null, null, View_AdminClientsListComponent_0, RenderType_AdminClientsListComponent)), i1.ɵdid(1, 49152, null, 0, i4.AdminClientsListComponent, [], null, null)], null, null); }
var AdminClientsListComponentNgFactory = i1.ɵccf("admin-clients-list", i4.AdminClientsListComponent, View_AdminClientsListComponent_Host_0, { clients: "clients" }, {}, []);
export { AdminClientsListComponentNgFactory as AdminClientsListComponentNgFactory };
var styles_AdminClientsComponent = [];
var RenderType_AdminClientsComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_AdminClientsComponent, data: {} });
export { RenderType_AdminClientsComponent as RenderType_AdminClientsComponent };
export function View_AdminClientsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { createFormModal: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "admin-clients"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "top-bar borderless clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "dashboard-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addClient() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" + new client "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "admin-clients-list", [], null, null, null, View_AdminClientsListComponent_0, RenderType_AdminClientsListComponent)), i1.ɵdid(8, 49152, null, 0, i4.AdminClientsListComponent, [], { clients: [0, "clients"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 3, "bs-modal", [], [[2, "fade", null], [2, "modal", null], [1, "role", 0], [1, "tabindex", 0]], null, null, i5.View_BsModalComponent_0, i5.RenderType_BsModalComponent)), i1.ɵdid(10, 4964352, [[1, 4], ["createFormModal", 4]], 0, i6.BsModalComponent, [i1.ElementRef, i7.BsModalService, i1.NgZone], null, null), (_l()(), i1.ɵeld(11, 0, null, 0, 1, "admin-client-form", [], null, [[null, "clientChange"], [null, "saved"], [null, "canceled"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clientChange" === en)) {
        var pd_0 = ((_co.newClient = $event) !== false);
        ad = (pd_0 && ad);
    } if (("saved" === en)) {
        var pd_1 = (_co.onClientCreate($event) !== false);
        ad = (pd_1 && ad);
    } if (("canceled" === en)) {
        var pd_2 = (_co.onClientCreateCancel() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i8.View_AdminClientFormComponent_0, i8.RenderType_AdminClientFormComponent)), i1.ɵdid(12, 114688, null, 0, i9.AdminClientFormComponent, [i10.ClientService], { client: [0, "client"] }, { saved: "saved", canceled: "canceled" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.clients; _ck(_v, 8, 0, currVal_0); _ck(_v, 10, 0); var currVal_5 = _co.newClient; _ck(_v, 12, 0, currVal_5); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 10).fadeClass; var currVal_2 = i1.ɵnov(_v, 10).modalClass; var currVal_3 = i1.ɵnov(_v, 10).roleAttr; var currVal_4 = i1.ɵnov(_v, 10).tabindexAttr; _ck(_v, 9, 0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
export function View_AdminClientsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "admin-clients", [], null, null, null, View_AdminClientsComponent_0, RenderType_AdminClientsComponent)), i1.ɵdid(1, 114688, null, 0, i4.AdminClientsComponent, [i10.ClientService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdminClientsComponentNgFactory = i1.ɵccf("admin-clients", i4.AdminClientsComponent, View_AdminClientsComponent_Host_0, {}, {}, []);
export { AdminClientsComponentNgFactory as AdminClientsComponentNgFactory };
