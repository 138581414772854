import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterLink } from '@angular/router';

import { appRoutingProviders, routing } from './app.routing';

import { BsModalModule } from 'ng2-bs3-modal';

import { AppHeaderComponent } from './app-header.component';

// ADMIN PANEL IMPORT START
import { AdminPanelComponent } from './admin-panel/admin-panel.component';


import { AdminLoginComponent, AdminLogoutComponent } from './admin-panel/admin-login/admin-login.component';
import { AdminDashboardComponent } from './admin-panel/dashboard/dashboard.component';

import { AdminAuditQuestionFormComponent } from './admin-panel/audit-questions/audit-question-form.component';
import { AdminAuditQuestionListComponent, AdminAuditQuestionsComponent } from './admin-panel/audit-questions/audit-questions.component';

import { AdminClientFormComponent } from './admin-panel/clients/client-form.component';
import { AdminClientComponent } from './admin-panel/clients/client.component';
import { AdminClientsComponent, AdminClientsListComponent } from './admin-panel/clients/clients.component';

import { AdminLicenseFormComponent } from './admin-panel/licenses/license-form.component';
import { AdminLicenseComponent } from './admin-panel/licenses/license.component';
import { AdminLicensesComponent, AdminLicensesListComponent } from './admin-panel/licenses/licenses.component';

import { AdminUserFormComponent } from './admin-panel/users/user-form.component';
import { AdminUserComponent } from './admin-panel/users/user.component';
import { AdminUsersComponent, AdminUsersListComponent } from './admin-panel/users/users.component';

import { AdminAuditTypeFormComponent } from './admin-panel/audit-types/audit-type-form.component';
import { AdminAuditTypeComponent } from './admin-panel/audit-types/audit-type.component';
import { AdminAuditTypesComponent, AdminAuditTypesListComponent } from './admin-panel/audit-types/audit-types.component';

import { AuditorCoreModule } from './core/auditor-core.module';

// ADMIN PANEL IMPORT END

import { MapToIterable } from './pipes/map-to-iterable.pipe';

import { AppComponent } from './app.component';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BsModalModule,
    AuditorCoreModule,
    routing
  ],
  providers: [

    // START THIRD PARTY SERVICES
    appRoutingProviders
    // END THIRD PARTY SERVICES
  ],
  declarations: [
    AppComponent,
    AppHeaderComponent,

    AdminLoginComponent,

    AdminAuditQuestionListComponent,
    AdminAuditQuestionFormComponent,
    AdminAuditQuestionsComponent,

    MapToIterable,

    // START ADMIN PANEL COMPONENTS
    AdminPanelComponent,

    AdminLoginComponent,
    AdminLogoutComponent,
    AdminDashboardComponent,

    AdminAuditQuestionFormComponent,
    AdminAuditQuestionListComponent, AdminAuditQuestionsComponent,

    AdminClientFormComponent,
    AdminClientComponent,
    AdminClientsComponent, AdminClientsListComponent,

    AdminLicenseFormComponent,
    AdminLicenseComponent,
    AdminLicensesComponent, AdminLicensesListComponent,

    AdminUserFormComponent,
    AdminUserComponent,
    AdminUsersComponent, AdminUsersListComponent,

    AdminAuditTypeFormComponent,
    AdminAuditTypeComponent,
    AdminAuditTypesComponent, AdminAuditTypesListComponent,
    // END ADMIN PANEL COMPONENTS
  ],
  bootstrap:    [ AppComponent ]
})
export class AppModule { }
