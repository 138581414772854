<div class="auditQuestion-form model-form">
  <form (ngSubmit)="save()" #loginForm="ngForm">

    <div [hidden]="!errors.server_error" class="alert alert-danger">
      {{errors.server_error}}
    </div>

    <div>
      <h4>Jurisdiction</h4>
      <div [hidden]="!errors.jurisdiction" class="alert alert-danger">
        {{ errors.jurisdiction }}
      </div>
      <div class="bar-menu actions">
        <div class="bar-menu-row">
          <div><button type="button" [class.active]="jurisdiction_type == 'city'" (click)="jurisdiction_type = 'city'">City</button></div>
          <div><button type="button" [class.active]="jurisdiction_type == 'county'" (click)="jurisdiction_type = 'county'">County</button></div>
          <div><button type="button" [class.active]="jurisdiction_type == 'state'" (click)="jurisdiction_type = 'state'">State</button></div>
          <div><button type="button" [class.active]="jurisdiction_type == 'country'" (click)="jurisdiction_type = 'country'">Country</button></div>
          <div><button type="button" [class.active]="jurisdiction_type == 'universal'" (click)="jurisdiction_type = 'universal'">Universal</button></div>
        </div>
      </div>


      <div class="form-group" *ngIf="jurisdiction_type == 'county'">
        <county-selector
          [(countyId)]="auditQuestion.county_id"
          [(county)]="auditQuestion.county"
          [(loading)]="loaders.countyLoading">
        </county-selector>
        <div [hidden]="!errors.county" class="alert alert-danger">
          {{ errors.county }}
        </div>
      </div>

      <div class="form-group" *ngIf="jurisdiction_type == 'city'">
        <city-selector
          [(cityId)]="auditQuestion.city_id"
          [(city)]="auditQuestion.city"
          [(loading)]="loaders.cityLoading">
        </city-selector>
        <div [hidden]="!errors.city" class="alert alert-danger">
          {{ errors.city }}
        </div>
      </div>

      <div class="form-group" *ngIf="jurisdiction_type == 'state'">
        <input type="text" class="form-control" name="state"
            [(ngModel)]="auditQuestion.state" placeholder="2 character state code">
        <div [hidden]="!errors.state" class="alert alert-danger">
          {{ errors.state }}
        </div>
      </div>

      <div class="form-group" *ngIf="jurisdiction_type == 'country'">
        <input type="text" class="form-control" name="state"
            [(ngModel)]="auditQuestion.country" placeholder="3 character country code">
        <div [hidden]="!errors.country" class="alert alert-danger">
          {{ errors.country }}
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="series">Series</label>
      <series-selector
        [(seriesId)]="auditQuestion.series_id"
        [(series)]="auditQuestion.series"
        [(loading)]="loaders.seriesLoading">
      </series-selector>
      <div [hidden]="!errors.series" class="alert alert-danger">
        {{ errors.series }}
      </div>
    </div>

    <div class="form-group">
      <label for="question_type">Question Type</label>
      <question-type-selector
        [(questionTypeId)]="auditQuestion.question_type_id"
        [(questionType)]="auditQuestion.question_type"
        [(loading)]="loaders.questionTypeLoading">
      </question-type-selector>
      <div [hidden]="!errors.question_type" class="alert alert-danger">
        {{ errors.question_type }}
      </div>
    </div>

    <div class="form-group">
      <label for="infraction_type">Infraction Type</label>
      <infraction-type-selector
        [(infractionTypeId)]="auditQuestion.infraction_type_id"
        [(infractionType)]="auditQuestion.infraction_type"
        [(loading)]="loaders.infractionTypeLoading">
      </infraction-type-selector>
      <div [hidden]="!errors.infraction_type" class="alert alert-danger">
        {{ errors.infraction_type }}
      </div>
    </div>

    <div class="form-group">
      <label for="question">Question</label>
      <textarea name="question" id="question" [(ngModel)]="auditQuestion.question"></textarea>
      <div [hidden]="!errors.question" class="alert alert-danger">
        {{ errors.question }}
      </div>
    </div>

    <div class="form-group">
      <label for="response">Response</label>
      <textarea name="response" id="response" [(ngModel)]="auditQuestion.response"></textarea>
      <div [hidden]="!errors.response" class="alert alert-danger">
        {{ errors.response }}
      </div>
    </div>



    <button type="submit" class="btn btn-default"
      [disabled]="loaders.countyLoading">
      Save
    </button>
    <button type="button" class="btn btn-alert" (click)="cancel()">Cancel</button>

  </form>
</div>
