/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../ui-helpers/auto-complete/auto-complete-base.ngfactory";
import * as i2 from "../ui-helpers/auto-complete/auto-complete-base";
import * as i3 from "./client-selector.component";
import * as i4 from "./client.service";
var styles_ClientSelectorComponent = [];
var RenderType_ClientSelectorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ClientSelectorComponent, data: {} });
export { RenderType_ClientSelectorComponent as RenderType_ClientSelectorComponent };
export function View_ClientSelectorComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "auto-complete", [], null, [[null, "loadingChange"], [null, "itemChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("loadingChange" === en)) {
        _co.loading = $event;
        var pd_0 = (_co.loadingChange.emit($event) !== false);
        ad = (pd_0 && ad);
    } if (("itemChange" === en)) {
        var pd_1 = ((_co.client = $event) !== false);
        ad = (pd_1 && ad);
    } if (("itemChange" === en)) {
        var pd_2 = (_co.onSelect($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_AutoCompleteBase_0, i1.RenderType_AutoCompleteBase)), i0.ɵdid(1, 573440, null, 0, i2.AutoCompleteBase, [], { autoCompleteApiService: [0, "autoCompleteApiService"], item: [1, "item"], formatTextFunc: [2, "formatTextFunc"] }, { itemChange: "itemChange", loadingChange: "loadingChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.autoCompleteApiService; var currVal_1 = _co.client; var currVal_2 = _co.formatButtonText; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_ClientSelectorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "client-selector", [], null, null, null, View_ClientSelectorComponent_0, RenderType_ClientSelectorComponent)), i0.ɵdid(1, 49152, null, 0, i3.ClientSelectorComponent, [i4.ClientService], null, null)], null, null); }
var ClientSelectorComponentNgFactory = i0.ɵccf("client-selector", i3.ClientSelectorComponent, View_ClientSelectorComponent_Host_0, { client: "client", clientId: "clientId", loading: "loading" }, { clientChange: "clientChange", clientIdChange: "clientIdChange", loadingChange: "loadingChange" }, []);
export { ClientSelectorComponentNgFactory as ClientSelectorComponentNgFactory };
