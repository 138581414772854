import { Injectable, Injector } from '@angular/core';
import { ApiResourceService } from '../api-resource.service';

@Injectable()
export class CityService extends ApiResourceService {
  public _apiPath = '/cities';
  constructor(private injector: Injector) {
   super(injector);
  }
}
