import {
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';

import { RouterLink } from '@angular/router';

import { BsModalComponent } from 'ng2-bs3-modal/ng2-bs3-modal';


import { Client } from '../../core/client/client';
import { ClientService } from '../../core/client/client.service';

@Component({
  selector: 'admin-clients-list',
  templateUrl: './clients-list.component.html',
  styleUrls:  ['./clients-list.component.less']
})
export class AdminClientsListComponent {
  @Input() clients: Client[];

  constructor(
  ) { }

}

@Component({
  selector: 'admin-clients',
  templateUrl: './clients.component.html',
  // styleUrls:  ['app/admin-panel/clients/clients.component.css']
})
export class AdminClientsComponent implements OnInit {

  clients: Client[];
  newClient: Client = new Client();
  @ViewChild('createFormModal', {static: false}) createFormModal: BsModalComponent;

  constructor(
    private clientService: ClientService
  ) { }

  ngOnInit() {
    this.getClients();
  }

  getClients() {
    this.clientService.getCollection().subscribe(clients => this.clients = clients);
  }

  addClient() {
    this.createFormModal.open();
  }
  onClientCreate(client: Client) {
    this.newClient = new Client();
    this.createFormModal.close();
    this.clients.unshift(client);
  }
  onClientCreateCancel() {
    this.createFormModal.close();
  }
}
