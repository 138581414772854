import {
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';

import { BsModalComponent } from 'ng2-bs3-modal/ng2-bs3-modal';

import { cloneDeep } from 'lodash';

import { Subscription } from 'rxjs/Subscription';

import { QuestionType } from '../../core/question-type/question-type';
import { QuestionTypeService } from '../../core/question-type/question-type.service';

import { AuditQuestion } from '../../core/audit-question/audit-question';
import { AuditQuestionService } from '../../core/audit-question/audit-question.service';

@Component({
  selector: 'admin-audit-questions-list',
  templateUrl: './audit-questions-list.component.html',
  styleUrls:  ['./audit-questions-list.component.less']
})
export class AdminAuditQuestionListComponent {
  @Input() auditQuestions: AuditQuestion[];

  editingQuestion: AuditQuestion = null;
  editingQuestionIndex: number = null;
  @ViewChild('editFormModal', {static: false}) editFormModal: BsModalComponent;

  removing: any = {};

  constructor(
    private auditQuestionService: AuditQuestionService
  ) {

  }

  editQuestion(index: number) {
    this.editingQuestion = cloneDeep(this.auditQuestions[index]);
    this.editingQuestionIndex = index;
    this.editFormModal.open();
  }
  removeQuestion(index: number) {
    const question = this.auditQuestions[index];
    this.removing[question.id] = true;
    this.auditQuestionService.deleteObject(question.id).subscribe(
      () => {
        delete this.removing[question.id];
        this.auditQuestions.splice(this.auditQuestions.indexOf(question), 1);
      },
      () => {
        delete this.removing[question.id];
        alert('error with request');
      }
    );
  }
  onQuestionEdit(question: AuditQuestion) {
    this.auditQuestions[this.editingQuestionIndex] = question;
    this.editingQuestion = null;
    this.editingQuestionIndex = null;
    this.editFormModal.close();
  }
  onQuestionEditCancel() {
    this.editingQuestion = null;
    this.editingQuestionIndex = null;
  }

}

@Component({
  selector: 'admin-audit-questions',
  templateUrl: './audit-questions.component.html',
  styleUrls:  ['./audit-questions-list.component.less'],
})
export class AdminAuditQuestionsComponent implements OnInit {

  constructor(
    private questionTypeService: QuestionTypeService,
    private auditQuestionService: AuditQuestionService
  ) { }

  auditQuestions: AuditQuestion[] = [];
  changes: any;
  questionTypes: QuestionType[];

  loadingQuestions = false;
  questionSubscription: Subscription;
  loadingQuestionTypes = false;
  loaders: any = {};

  errors: any = false;

  page = 1;

  listType = 'draft';

  selectedQuestionTypes: number[] = [];

  jurisdiction_type = 'none';

  newQuestion: AuditQuestion = new AuditQuestion();
  questionTemplate: AuditQuestion = new AuditQuestion();
  @ViewChild('createFormModal', {static: false}) createFormModal: BsModalComponent;

  questionFormActive = false;

  ngOnInit() {
    this.getQuestionTypes();
  }

  getQuestions(e?: any) {
    this.getQuestionsAction();
  }

  getQueryParams(): any {
    const params = {};

    if (this.listType === 'draft') {
      params['edit_draft'] = 'true';
    }

    if (this.selectedQuestionTypes.length > 0) {
      params['question_types'] = this.selectedQuestionTypes.join(',');
    }

    if (this.jurisdiction_type === 'city' && this.questionTemplate.city) {
      params['city_id'] = this.questionTemplate.city.id.toString();
    }
    if (this.jurisdiction_type === 'county' && this.questionTemplate.county) {
      params['county_id'] = this.questionTemplate.county.id.toString();
    }
    if (this.jurisdiction_type === 'state') {
      params['jurisdiction[state]'] = this.questionTemplate.state;
    }
    if (this.jurisdiction_type === 'country') {
      params['jurisdiction[country]'] = this.questionTemplate.country;
    }
    if (this.jurisdiction_type === 'universal') {
      params['jurisdiction[is_universal]'] = 'true';
    }

    if (this.questionTemplate.series_id) {
      params['series_id'] = this.questionTemplate.series_id.toString();
    }
    if (this.questionTemplate.infraction_type_id) {
      params['infraction_type_id'] = this.questionTemplate.infraction_type_id.toString();
    }
    return params;
  }

  getQuestionsAction() {

    if (this.questionSubscription) {
      this.questionSubscription.unsubscribe();
      this.questionSubscription = null;
    }

    this.loadingQuestions = true;
    this.errors = false;
    this.auditQuestions = [];

    const params = this.getQueryParams();

    const options = {
      params: params
    };

    if (this.listType === 'changes') {
      this.questionSubscription = this.auditQuestionService.getDraftDiff(options).subscribe(changes => {
        this.changes = changes;
        this.loadingQuestions = false;
        this.questionSubscription = null;
      }, errors => {
        this.errors = errors.messages;
        this.loadingQuestions = false;
        this.questionSubscription = null;
      });
    } else {
      this.questionSubscription = this.auditQuestionService.getCollection(options).subscribe(auditQuestions => {
        this.auditQuestions = auditQuestions;
        this.loadingQuestions = false;
        this.questionSubscription = null;
      }, errors => {
        this.errors = errors.messages;
        this.loadingQuestions = false;
        this.questionSubscription = null;
      });
    }
  }

  getQuestionTypes() {
    this.loadingQuestionTypes = true;
    this.questionTypeService.getCachedCollection().subscribe(questionTypes => {
      this.questionTypes = questionTypes;
      this.loadingQuestionTypes = false;
    });
  }

  toggleQuestionType(id) {
    const index = this.selectedQuestionTypes.indexOf(id);

    if (index !== -1) {
      this.selectedQuestionTypes.splice(index, 1);
      if (this.selectedQuestionTypes[0]) {
        this.questionTemplate.question_type_id = this.selectedQuestionTypes[0];
      } else {
        this.questionTemplate.question_type_id = undefined;
      }
    } else {
      this.selectedQuestionTypes.push(id);
      this.questionTemplate.question_type_id = id;
    }
  }

  isActiveQuestionType(id): boolean {
    return (this.selectedQuestionTypes.indexOf(id) !== -1);
  }

  setListType(type) {
    this.listType = type;
    this.getQuestions();
  }

  addQuestion() {
    this.questionFormActive = true;
    this.newQuestion = cloneDeep(this.questionTemplate);
    this.createFormModal.open();
  }
  onQuestionCreate(question: AuditQuestion) {
    this.questionFormActive = false;
    this.auditQuestions.unshift(question);
    this.newQuestion = new AuditQuestion();
    this.createFormModal.close();
  }
  onQuesticanceled(any) {
    this.questionFormActive = false;
    this.createFormModal.close();
  }
  resetDraft(): void {
    const params = this.getQueryParams();
    const options = {
      params: params
    };
    this.auditQuestionService.resetDraft(options).subscribe(
      () => this.getQuestions(),
      (errors) => this.errors = errors
    );
  }
  applyDraft(): void {
    const params = this.getQueryParams();
    const options = {
      params: params
    };
    this.auditQuestionService.applyDraft(options).subscribe(
      () => this.getQuestions(),
      (errors) => this.errors = errors
    );
  }

}
