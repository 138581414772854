import {
  Component,
  OnInit,
  ViewChild
} from '@angular/core';

import { ActivatedRoute } from '@angular/router';

import { BsModalComponent } from 'ng2-bs3-modal/ng2-bs3-modal';

import { cloneDeep } from 'lodash';

import { Client } from '../../core/client/client';
import { ClientService } from '../../core/client/client.service';

import { License } from '../../core/license/license';
import { LicenseService } from '../../core/license/license.service';

@Component({
  selector: 'admin-client',
  templateUrl: './client.component.html',
  // styleUrls:  ['./client.component.css']
})
export class AdminClientComponent implements OnInit {

  client: Client;
  clientEdit: Client = new Client();
  clientIsLoading: boolean;
  clientLoadErrors = {};
  @ViewChild('editFormModal', {static: false}) editFormModal: BsModalComponent;

  licenses: License[];
  licensesLoading: boolean;
  newLicense: License = new License();
  @ViewChild('addLicenseModal', {static: false}) addLicenseModal: BsModalComponent;

  constructor(
    private route: ActivatedRoute,
    private clientService: ClientService,
    private licenseService: LicenseService
  ) { }

  ngOnInit() {
    const id = this.route.snapshot.params.id;
    this.loadClient(id);
  }

  loadClient(id) {
    this.newLicense.client_id = id;
    this.loadLicenses(id);

    this.clientIsLoading = true;
    this.clientService.getObject(id).subscribe(
      client => {
        this.client = client;
        this.clientIsLoading = false;
      },
      error => {
        console.log(error);
        this.clientLoadErrors = error.messages;
        this.clientIsLoading = false;
      }
    );
  }

  editClient() {
    this.clientEdit = cloneDeep(this.client);
    this.editFormModal.open();
  }

  onClientSave(client) {
    this.client = client;
    this.editFormModal.close();
  }

  loadLicenses(clientId?) {
    this.licensesLoading = true;
    clientId = clientId || this.client.id;
    const params = {};
    params['client_id'] = clientId;
    const options = {
      params: params
    };
    this.licenseService.getCollection(options).subscribe(
      licenses => {
        this.licenses = licenses;
        this.licensesLoading = false;
      },
      errors => {
        this.licensesLoading = false;
      }
    );
  }

  onLicenseSave(license) {
    this.addLicenseModal.close();
    this.licenses.unshift(license);
    this.newLicense = new License();
    this.newLicense.client_id = this.client.id;
  }
}
