import { Component, OnInit } from '@angular/core';

import { AuthService } from '../../core/auth/auth.service';

@Component({
  selector: 'admin-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls:  ['./dashboard.component.less']
})
export class AdminDashboardComponent implements OnInit {

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit() {

  }
}
