<div class="user-form model-form">
  <form (ngSubmit)="save()" #loginForm="ngForm" autocomplete="off">
    <div [hidden]="!errors.server_error" class="alert alert-danger">
      {{errors.server_error}}
    </div>

    <div class="form-group">
      <label for="email">Email</label>
      <input type="email" class="form-control"
          autocomplete="off"
          [(ngModel)]="user.email"
          name="email">
      <div [hidden]="!errors.email" class="alert alert-danger">
        {{ errors.email }}
      </div>
    </div>

    <div class="form-group">
      <label for="name">Name</label>
      <input type="text" class="form-control"
          autocomplete="off"
          [(ngModel)]="user.name"
          name="name">
      <div [hidden]="!errors.name" class="alert alert-danger">
        {{ errors.name }}
      </div>
    </div>

    <div class="form-group">
      <label for="phone">Phone</label>
      <input type="tel" class="form-control"
          autocomplete="off"
          [(ngModel)]="user.phone"
          name="phone">
      <div [hidden]="!errors.phone" class="alert alert-danger">
        {{ errors.phone }}
      </div>
    </div>

    <div class="form-group">
      <label for="password">Password</label>
      <input type="password" class="form-control"
          autocomplete="off"
          [(ngModel)]="user.password"
          name="password">
      <div [hidden]="!errors.password" class="alert alert-danger">
        {{ errors.password }}
      </div>
    </div>

    <div class="form-group">
      <label for="is_active">Active</label>
      <input type="checkbox" class=""
          [(ngModel)]="user.is_active"
          name="is_active">
    </div>

    <div class="form-group">
      <label for="docusign_exempt">Docusign Exempt</label>
      <input type="checkbox" class=""
          [(ngModel)]="user.docusign_exempt"
          name="docusign_exempt">
    </div>

    <div class="form-group">
      <label for="is_admin">Is Admin?</label>
      <input type="checkbox" class=""
          [(ngModel)]="user.is_admin"
          name="is_admin">
    </div>

    <button type="submit" class="btn btn-default">Save</button>
    <button type="button" class="btn btn-alert" (click)="cancel()">Cancel</button>

  </form>
</div>
