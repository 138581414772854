import { Injectable, Injector } from '@angular/core';
import { LicenseType } from './license-type';

import { Observable, Observer } from 'rxjs';

import { ApiResourceService } from '../api-resource.service';

@Injectable()
export class LicenseTypeService extends ApiResourceService {
  protected _apiPath = '/license_types';

  protected _cachedCollection: LicenseType[];
  protected _cachedSubscribe: Observable<LicenseType[]>;

  constructor(private injector: Injector) {
   super(injector);
  }

  protected softConstructor(
  ) {
    this.getCachedCollection().subscribe(types => this._cachedCollection = types);
  }

  getCachedCollection(options?: any): Observable<LicenseType[]> {
    if (this._cachedCollection) {
      let observer: Observer<LicenseType[]>;
      setTimeout(() => observer.next(this._cachedCollection));
      return new Observable(setObserver => observer = setObserver);
    } else if (this._cachedSubscribe) {
      return this._cachedSubscribe;
    } else {
      this._cachedSubscribe = this.getCollection(options);
      return this._cachedSubscribe;
    }
  }

}
