import { Pipe } from '@angular/core';

@Pipe({
  name: 'mapToIterable'
})
export class MapToIterable {
  transform(dict: any): any[] {
    const a = [];
    for (const key in dict) {
      if (dict.hasOwnProperty(key)) {
        a.push({key, val: dict[key]});
      }
    }
    return a;
  }
}
