import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild
} from '@angular/core';

import { Observable } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';

import { cloneDeep } from 'lodash';

@Component({
  selector: 'auto-complete',
  templateUrl: './auto-complete-default.html',
  // styleUrls:  ['app/core/county/county-selector.component.css']
})
export class AutoCompleteBase implements OnChanges {

  @Input() autoCompleteApiService: any;

  // Use these for child class events
  @Input() item: any;
  @Output() itemChange = new EventEmitter<any>();
  @Input() formatTextFunc: Function;
  @Output() addNewFunc = new EventEmitter<any>();
  @Input() queryTimeoutDuration = 300;

  @ViewChild('resultComponent', {static: false}) resultComponent;
  @ViewChild('inputElem', {static: false}) inputElem;

  isFocused = false;

  public typedIn = '';

  private lastRequest: string = null;
  private searchSubscription: Subscription;
  private queryTimeout;

  @Output() loadingChange = new EventEmitter<boolean>();
  public autoCompleteLoading: boolean;
  public autoCompleteError: boolean;
  public autoCompleteNoResults: boolean;
  public autoCompleteResults: any[];

  public showDrop = false;

  public focusedIndex = 0;
  public addNewFuncIndex: number = null;

  totalItems = 0;

  clickingOption = false;

  formatButtonText(item: any): string {
    if (!item) {
      return '';
    } else if (this.formatTextFunc) {
      return this.formatTextFunc(item);
    } else {
      return item.toString();
    }
  }

  loadSelection(item) {
    this.typedIn = this.formatButtonText(item);
    this.lastRequest = this.typedIn;
  }
  ngOnChanges(changes) {
    this.calcTotalItems();
    if (changes.item && this.item) {
      this.loadSelection(this.item);
    }
  }

  calcTotalItems() {
    this.totalItems = this.autoCompleteResults ? this.autoCompleteResults.length : 0;
    if (this.addNewFunc.observers.length > 0) {
      this.addNewFuncIndex = this.totalItems;
      this.totalItems++;
    } else {
      this.addNewFuncIndex = null;
    }
  }

  doSearch(): Observable<any> {
    this.showDrop = true;
    const params = {};
    params['typeahead'] = this.typedIn;
    const options = {
      params: params
    };
    return this.autoCompleteApiService.getCollection(options);
  }
  inputKeyDown(e) {
    if (e.keyCode === 38) {
      e.preventDefault();
      this.selectPrev();
      // up arrow
    } else if (e.keyCode === 40) {
      e.preventDefault();
      this.selectNext();
      // down arrow
    } else if (e.keyCode === 13) {
      if (this.showDrop) {
        e.preventDefault();
        if (!this.autoCompleteLoading) {
          this.applySelection(this.focusedIndex);
        }
        return false;
      }
      // enter
    }
  }
  inputKeyUp(e): void {
    let doesSearch = false;
    if (typeof(e) === 'object') {
      if (e.type === 'keyup') {
        if (this.typedIn !== this.lastRequest) {
          doesSearch = true;
        }
      }
    }
    if (doesSearch) {
      this.setupAndDoSearch();
    }
  }
  setupAndDoSearch(): void {
    if (this.lastRequest === this.typedIn && this.autoCompleteResults) {
      this.showDrop = true;
      return;
    } else {
      if (this.item && this.typedIn !== this.formatButtonText(this.item)) {
        this.item = null;
        this.itemChange.emit(this.item);
      }
    }

    this.lastRequest = cloneDeep(this.typedIn);
    this.autoCompleteResults = null;
    this.autoCompleteNoResults = false;
    this.autoCompleteError = false;
    this.autoCompleteLoading = true;
    this.loadingChange.emit(this.autoCompleteLoading);

    this.focusedIndex = 0;

    if (this.queryTimeout) {
      clearTimeout(this.queryTimeout);
    }

    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
      this.searchSubscription = null;
    }
    this.queryTimeout = setTimeout(() => {
      this.queryTimeout = null;
      this.searchSubscription = this.doSearch().subscribe(
        results => {
          if (results.length === 0) {
            this.autoCompleteNoResults = true;
          } else {
            this.focusedIndex = 0;
            this.autoCompleteResults = results;
          }
          this.calcTotalItems();
          this.autoCompleteLoading = false;
          this.loadingChange.emit(this.autoCompleteLoading);
        },
        errors => {
          this.autoCompleteError = true;
          this.autoCompleteLoading = false;
          this.loadingChange.emit(this.autoCompleteLoading);
        }
      );
    }, this.queryTimeoutDuration);
  }
  selectNext(): void {
    if (!this.autoCompleteResults) {
      return;
    }
    const limit = this.totalItems - 1;
    if (this.focusedIndex < limit) {
      this.focusedIndex++;
      this.focusActive();
    }
  }
  selectPrev(): void {
    if (!this.autoCompleteResults) {
      return;
    }
    if (this.focusedIndex > 0) {
      this.focusedIndex--;
      this.focusActive();
    }
  }
  focusActive(): void {
    if (this.resultComponent.nativeElement.children[this.focusedIndex]) {
      this.resultComponent.nativeElement.children[this.focusedIndex].focus();
      this.inputElem.nativeElement.focus();
    }
  }
  applySelection(index: number): void {
    if (index === this.addNewFuncIndex) {
      this.addNewFunc.emit(this.typedIn);
    } else {
      this.item = this.autoCompleteResults[index];
      this.itemChange.emit(this.item);
      this.typedIn = this.formatButtonText(this.item);
      this.lastRequest = this.typedIn;
      this.autoCompleteResults = null;
      this.focusedIndex = 0;
    }
    this.clickingOption = false;
    this.hideDrop();
  }
  hideDrop(): void {
    if (!this.clickingOption) {
      this.showDrop = false;
    }
  }
}
