<div class="county-form model-form">
  <form (ngSubmit)="save()" #loginForm="ngForm">

    <div [hidden]="!errors.server_error" class="alert alert-danger">
      {{errors.server_error}}
    </div>

    <div class="form-group">
      <label for="county">County</label>
      <input type="text" class="form-control"
          [(ngModel)]="county.county"
          name="county">
      <div [hidden]="!errors.county" class="alert alert-danger">
        {{ errors.county }}
      </div>
    </div>

    <div class="form-group">
      <label for="state">State</label>
      <input type="text" class="form-control"
          [(ngModel)]="county.state"
          name="state">
      <div [hidden]="!errors.state" class="alert alert-danger">
        {{ errors.state }}
      </div>
    </div>

    <button type="submit" class="btn btn-default">Save</button>
    <button type="button" class="btn btn-alert" (click)="cancel()">Cancel</button>

  </form>
</div>
