<div *ngIf="client" class="admin-client">
  <div class="top-bar clearfix">
    <div class="actions">
      <button (click)="editClient()" type="button">
        edit
      </button>
    </div>
    <h1>{{client.client_name}}</h1>
  </div>

  <div class="dashboard-content">
    <div class="info clearfix">
      <div class="location-cont">
        <h4>Location</h4>
        <div>{{ client.address }}</div>
        <div>{{ client.address2 }}</div>
        <div>city: {{ client.city }}</div>
        <div>county: {{ client.county }}</div>
        <div>state: {{ client.state }}</div>
        <div>zip: {{ client.zip }}</div>
      </div>
    </div>

    <hr>

    <h4>Licenses</h4>
    <div class="actions">
      <button (click)="addLicenseModal.open()" type="button">
        + new license
      </button>
    </div>
    <admin-licenses-list [licenses]="licenses"></admin-licenses-list>

  </div>

  <bs-modal #editFormModal>
    <admin-client-form [client]="clientEdit" (saved)="onClientSave($event)" (canceled)="editFormModal.close()"></admin-client-form>
  </bs-modal>

  <bs-modal #addLicenseModal>
    <admin-license-form [license]="newLicense" (saved)="onLicenseSave($event)" (canceled)="addLicenseModal.close()"></admin-license-form>
  </bs-modal>

</div>
<div *ngIf="!client" class="top-bar">
  <span [hidden]="!clientIsLoading">loading...</span>
  <span *ngFor="let error of clientLoadErrors | mapToIterable">{{ error.val }} <br></span>
</div>
