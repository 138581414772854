import { Injectable, Injector } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable()
export abstract class ApiResourceService {
  protected _apiService: ApiService;
  protected abstract _apiPath: string;
  protected softConstructor() {

  }

  constructor(protected injectorObj: Injector) {
    this._apiService = this.injectorObj.get(ApiService);
    setTimeout(() => this.softConstructor());
  }

  getCollection(options?: any): Observable<any[]> {
    return this._apiService.get(this._apiPath, options).pipe(map((res) => {
      return this.handleCollectionResponse(res);
    }, (error) => {
      return this.handleBasicError(error);
    }));
  }
  protected handleCollectionResponse(res: any): any[] {
    return res;
  }
  getObject(id, options?: any): Observable<any> {
    return this._apiService.get(this._apiPath + '/' + id, options).pipe(map((res) => {
      return this.handleObjectResponse(res);
    }, (error) => {
      return this.handleBasicError(error);
    }));
  }
  save(model: any, options?: any): Observable<any> {
    let request;
    if (model.id) {
      request = this._apiService.patch(this._apiPath + '/' + model.id, model, options);
    } else {
      request = this._apiService.post(this._apiPath, model, options);
    }

    return request.map(res => this.handleObjectResponse(res)).pipe(map((res) => {
      return this.handleObjectResponse(res);
    }, (error) => {
      return this.handleBasicError(error);
    }));
  }
  protected handleObjectResponse(res: any): any {
    return res;
  }

  deleteObject(id, options?: any): Observable<void> {
    return this._apiService.delete(this._apiPath + '/' + id, options).pipe(map((res) => {
      return this.handleObjectDeleteResponse(res);
    }, (error) => {
      return this.handleBasicError(error);
    }));
  }
  private handleObjectDeleteResponse(res: any): any {
    return;
  }
  protected handleBasicError(error: any) {
    return throwError(error);
  }

}
