import {
  Component,
  OnInit,
  ViewChild
} from '@angular/core';


import { ActivatedRoute } from '@angular/router';

import { BsModalComponent } from 'ng2-bs3-modal/ng2-bs3-modal';

import { cloneDeep } from 'lodash';

import { User } from '../../core/user/user';
import { UserService } from '../../core/user/user.service';

import { Client } from '../../core/client/client';
import { ClientService } from '../../core/client/client.service';

import { ClientUser } from '../../core/client-user/client-user';
import { ClientUserFormComponent } from '../../core/client-user/client-user-form.component';
import { ClientUserService } from '../../core/client-user/client-user.service';

import { AdminClientsListComponent } from '../clients/clients.component';

import { AdminUserFormComponent } from './user-form.component';

import { MapToIterable } from '../../pipes/map-to-iterable.pipe';

@Component({
  selector: 'admin-user',
  templateUrl: './user.component.html',
  // styleUrls:  ['app/admin-panel/users/user.component.css']
})
export class AdminUserComponent implements OnInit {

  user: User;
  userEdit: User = new User();
  userIsLoading: boolean;
  userLoadErrors = {};
  @ViewChild('editFormModal', {static: false}) editFormModal: BsModalComponent;
  @ViewChild('connectClientModal', {static: false}) connectClientModal: BsModalComponent;

  clients: Client[];
  clientsLoading: boolean;

  accessAggregate: any[];
  accessAggregateLoading: boolean;

  newClientUser = new ClientUser();

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private clientService: ClientService,
    private clientUserService: ClientUserService
  ) { }

  ngOnInit() {
    const id = this.route.snapshot.params.id;
    this.loadUser(id);
    this.newClientUser.user_id = parseInt(id);
  }

  loadUser(id) {
    this.userIsLoading = true;
    this.userService.getObject(id).subscribe(
      user => {
        this.user = user;
        this.userIsLoading = false;
      },
      error => {
        console.log(error);
        this.userLoadErrors = error.messages;
        this.userIsLoading = false;
      }
    );
    this.loadClients(id);
    this.loadAccessAggregate(id);
  }

  loadClients(userId?) {
    this.clientsLoading = true;
    userId = userId || this.user.id;
    const params = {};
    params['user_id'] = userId;
    const options = {
      params: params
    };
    this.clientService.getCollection(options).subscribe(
      clients => {
        this.clients = clients;
        this.clientsLoading = false;
      },
      errors => {
        this.clientsLoading = false;
      }
    );
  }

  loadAccessAggregate(userId?) {
    this.accessAggregateLoading = true;
    userId = userId || this.user.id;
    const params = {};
    params['user_id'] = userId;
    params['group_by'] = 'ip_address';
    const options = {
      params: params
    };
    this.userService.getAccessOriginAggregate(options).subscribe(
      accessAggregate => {
        this.accessAggregate = accessAggregate;
        this.accessAggregateLoading = false;
      },
      errors => {
        this.accessAggregateLoading = false;
      }
    );
  }

  onClientUserSave() {
    this.newClientUser = {user_id: this.user.id} as ClientUser;
    this.loadClients();
    this.connectClientModal.close();
  }

  removeClientUser(index) {
    this.clientUserService.deleteObject(this.clients[index].client_user.id).subscribe();
    this.clients.splice(index, 1);
  }

  editUser() {
    this.userEdit = cloneDeep(this.user);
    this.editFormModal.open();
  }

  onUserSave(user) {
    this.user = user;
    this.editFormModal.close();
  }
}
