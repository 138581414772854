import { NgModule }      from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { AutoCompleteBase } from './ui-helpers/auto-complete/auto-complete-base';
import { LoadingComponent } from './ui-helpers/loading.component';

import { CitySelectorComponent } from './city/city-selector.component';
import { ClientSelectorComponent } from './client/client-selector.component';
import { CountySelectorComponent } from './county/county-selector.component';
import { InfractionTypeSelectorComponent } from './infraction-type/infraction-type-selector.component';
import { QuestionTypeSelectorComponent } from './question-type/question-type-selector.component';
import { SeriesSelectorComponent } from './series/series-selector.component';

import { CityFormComponent } from './city/city-form.component';
import { ClientUserFormComponent } from './client-user/client-user-form.component';
import { CountyFormComponent } from './county/county-form.component';

import { ApiService } from './api.service';
import { AuthGuard, AuthService } from './auth/auth.service';

import { BsModalModule } from 'ng2-bs3-modal';
import { AuditQuestionService } from './audit-question/audit-question.service';
import { AuditTypeService } from './audit-type/audit-type.service';
import { CityService } from './city/city.service';
import { ClientUserService } from './client-user/client-user.service';
import { ClientService } from './client/client.service';
import { CountyService } from './county/county.service';
import { InfractionTypeService } from './infraction-type/infraction-type.service';
import { LicenseTypeService } from './license-type/license-type.service';
import { LicenseService } from './license/license.service';
import { PaymentMethodService } from './payment-method/payment-method.service';
import { QuestionTypeService } from './question-type/question-type.service';
import { SeriesService } from './series/series.service';
import { UserService } from './user/user.service';

@NgModule({
  imports: [
    FormsModule,
    BrowserModule,
    BsModalModule
  ],
  exports: [
    CitySelectorComponent,
    ClientSelectorComponent,
    CountySelectorComponent,
    InfractionTypeSelectorComponent,
    QuestionTypeSelectorComponent,
    SeriesSelectorComponent,
    ClientUserFormComponent,
    CityFormComponent,
    CountyFormComponent
  ],
  providers: [
    ApiService,
    AuthService,
    AuthGuard,

    AuditQuestionService,
    AuditTypeService,
    CityService,
    ClientService,
    ClientUserService,
    CountyService,
    InfractionTypeService,
    LicenseService,
    LicenseTypeService,
    QuestionTypeService,
    PaymentMethodService,
    SeriesService,
    UserService
  ],
  declarations: [
    AutoCompleteBase,
    LoadingComponent,

    CitySelectorComponent,
    ClientSelectorComponent,
    CountySelectorComponent,
    InfractionTypeSelectorComponent,
    QuestionTypeSelectorComponent,
    SeriesSelectorComponent,

    ClientUserFormComponent,
    CityFormComponent,
    CountyFormComponent
  ],
  bootstrap:    []
})
export class AuditorCoreModule { }
