<div class="admin-panel">

  <div class="content-body clearfix">
    <ul *ngIf="currentUser" class="dashboard-nav-menu">
      <li>
        <a
           routerLinkActive="active"
          routerLink="/admin/dashboard">
          Dashboard
        </a>
      </li>
      <li>
        <a
           routerLinkActive="active"
          routerLink="/admin/users">
          Users
        </a>
      </li>
      <li>
        <a
           routerLinkActive="active"
          routerLink="/admin/clients">
          Clients
        </a>
      </li>
      <li>
        <a
           routerLinkActive="active"
          routerLink="/admin/licenses">
          Licenses
        </a>
      </li>
      <li>
        <a
          routerLinkActive="active"
          routerLink="/admin/audit-types">
          Audit Types
        </a>
      </li>
      <li>
        <a
          routerLinkActive="active"
          routerLink="/admin/audit-questions">
          Audit Questions
        </a>
      </li>
      <li>
        <hr>
      </li>
      <li>
        <a
          routerLink="/logout">
          Logout
        </a>
      </li>
    </ul>
    <div class="dashboard-body">
      <router-outlet></router-outlet>
    </div>
  </div>

</div>
