import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuditTypeService } from '../core/audit-type/audit-type.service';
import { AuthService } from '../core/auth/auth.service';
import { QuestionTypeService } from '../core/question-type/question-type.service';
import { User } from '../core/user/user';

@Component({
  selector: 'admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls:  ['./admin-panel.component.less']
})
export class AdminPanelComponent implements OnInit {
  currentUser: User;

  constructor(
    private router: Router,
    private auditTypeService: AuditTypeService,
    private authService: AuthService) {

    }

  ngOnInit() {
    this.currentUser = this.authService.currentUser;
    this.authService.currentUser$.subscribe(user => {
      this.currentUser = user;
    });
  }
}
