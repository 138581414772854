<div class="admin-licenses">
  <div class="top-bar borderless clearfix">
  </div>
  <div class="dashboard-content">
    <div class="actions">
      <div class="form-group">
        <label>Question Types</label>
        <br>
        <span *ngFor="let type of questionTypes">
          <button [class.active]="isActiveQuestionType(type.id)" (click)="toggleQuestionType(type.id)">
            {{type.id_public}}
          </button>
        </span>
      </div>
      <div class="form-group">
        <label>Jurisdiction</label>
        <br>
        <div class="bar-menu actions">
          <div class="bar-menu-row">
            <div><button type="button" [class.active]="jurisdiction_type == 'none'" (click)="jurisdiction_type = 'none'">None</button></div>
            <div><button type="button" [class.active]="jurisdiction_type == 'city'" (click)="jurisdiction_type = 'city'">City</button></div>
            <div><button type="button" [class.active]="jurisdiction_type == 'county'" (click)="jurisdiction_type = 'county'">County</button></div>
            <div><button type="button" [class.active]="jurisdiction_type == 'state'" (click)="jurisdiction_type = 'state'">State</button></div>
            <div><button type="button" [class.active]="jurisdiction_type == 'country'" (click)="jurisdiction_type = 'country'">Country</button></div>
            <div><button type="button" [class.active]="jurisdiction_type == 'universal'" (click)="jurisdiction_type = 'universal'">Universal</button></div>
          </div>
        </div>


        <div class="form-group" *ngIf="jurisdiction_type == 'county'">
          <county-selector
            [(countyId)]="questionTemplate.county_id"
            [(county)]="questionTemplate.county"
            [(loading)]="loaders.countyLoading">
          </county-selector>
          <div [hidden]="!errors.county" class="alert alert-danger">
            {{ errors.county }}
          </div>
        </div>

        <div class="form-group" *ngIf="jurisdiction_type == 'city'">
          <city-selector
            [(cityId)]="questionTemplate.city_id"
            [(city)]="questionTemplate.city"
            [(loading)]="loaders.cityLoading">
          </city-selector>
          <div [hidden]="!errors.city" class="alert alert-danger">
            {{ errors.city }}
          </div>
        </div>

        <div class="form-group" *ngIf="jurisdiction_type == 'state'">
          <input type="text" class="form-control" name="state"
              [(ngModel)]="questionTemplate.state" placeholder="2 character state code">
          <div [hidden]="!errors.state" class="alert alert-danger">
            {{ errors.state }}
          </div>
        </div>

        <div class="form-group" *ngIf="jurisdiction_type == 'country'">
          <input type="text" class="form-control" name="country"
              [(ngModel)]="questionTemplate.country" placeholder="3 character country code">
          <div [hidden]="!errors.country" class="alert alert-danger">
            {{ errors.country }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="series">Series</label>
        <series-selector
          [(seriesId)]="questionTemplate.series_id"
          [(series)]="questionTemplate.series"
          [(loading)]="loaders.seriesLoading">
        </series-selector>
        <div [hidden]="!errors.series" class="alert alert-danger">
          {{ errors.series }}
        </div>
      </div>

      <div class="form-group">
        <label for="infraction_type">Infraction Type</label>
        <infraction-type-selector
          [(infractionTypeId)]="questionTemplate.infraction_type_id"
          [(infractionType)]="questionTemplate.infraction_type"
          [(loading)]="loaders.infractionTypeLoading">
        </infraction-type-selector>
        <div [hidden]="!errors.infraction_type" class="alert alert-danger">
          {{ errors.infraction_type }}
        </div>
      </div>

      <div class="form-group">
        <button type="button" (click)="getQuestions()">apply filters</button>
      </div>
    </div>

    <div *ngIf="errors" class="alert alert-danger">
      <div *ngIf="errors.jurisdiction">
        {{ errors.jurisdiction.city }}
        {{ errors.jurisdiction.county }}
        {{ errors.jurisdiction.state }}
      </div>
    </div>

    <div class="bar-menu actions">
      <div class="bar-menu-row">
        <div><button [class.active]="listType == 'current'" (click)="setListType('current')">Current</button></div>
        <div><button [class.active]="listType == 'draft'" (click)="setListType('draft')">Edit Draft</button></div>
        <div><button [class.active]="listType == 'changes'" (click)="setListType('changes')">Changes</button></div>
      </div>
    </div>

    <div *ngIf="listType == 'draft'">
      <hr>
      <button (click)="addQuestion()">Add Question</button>
      <hr>
    </div>

    <div *ngIf="listType == 'changes'">
      <hr>
      <button (click)="confirmApplyModal.open()">Commit Changes</button>
      <button (click)="confirmResetModal.open()">Reset Changes</button>
      <hr>
    </div>

    <admin-audit-questions-list [hidden]="listType == 'changes'" [auditQuestions]="auditQuestions"></admin-audit-questions-list>

    <div [hidden]="listType != 'changes'">
      <div *ngIf="changes && !loadingQuestions">
        <h2 class="green-text">Added</h2>
        <admin-audit-questions-list [auditQuestions]="changes.added"></admin-audit-questions-list>
        <span [hidden]="changes.added.length">no questions added</span>
        <h2 class="red-text">Removed</h2>
        <admin-audit-questions-list [auditQuestions]="changes.removed"></admin-audit-questions-list>
        <span [hidden]="changes.removed.length">no questions removed</span>
        <h2>Changes</h2>
        <div *ngFor="let diff of changes.changed_diffs; let index = index;"
          class="question-row relative clearfix">

          <h3>{{diff.original.id_public}}</h3>
          <span class="detail-info inner-shadow-soft">
            <span class="tabular-cell jurisdiction" [class.red-text]="diff.changes.state || diff.changes.county || diff.changes.city">
              <span *ngIf="diff.original.state">
                <strong>state:</strong> {{diff.original.state}}
              </span>
              <span *ngIf="diff.original.county">
                <strong>county:</strong> {{diff.original.county.county}}, {{diff.original.county.state}}
              </span>
              <span *ngIf="diff.original.city">
                <strong>city:</strong> {{diff.original.city.city}}, {{diff.original.city.state}}
              </span>
            </span>
            <span class="tabular-cell jurisdiction" class="green-text" *ngIf="diff.changes.state || diff.changes.county || diff.changes.city">
              <span *ngIf="diff.changes.state">
                <strong>state:</strong> {{diff.changes.state}}
              </span>
              <span *ngIf="diff.changes.county">
                <strong>county:</strong> {{diff.changes.county.county}}, {{diff.changes.county.state}}
              </span>
              <span *ngIf="diff.changes.city">
                <strong>city:</strong> {{diff.changes.city.city}}, {{diff.changes.city.state}}
              </span>
            </span>
            <br>
            <span [class.red-text]="diff.changes.series">
              <strong>series:</strong> {{diff.original.series.id_public}}
            </span>
            <span class="green-text" *ngIf="diff.changes.series">
              <strong>series:</strong> {{diff.changes.series.id_public}}
            </span>
            <br>
            <span [class.red-text]="diff.changes.question_type">
              <strong>question type:</strong> {{diff.original.question_type.id_public}}
            </span>
            <span class="green-text" *ngIf="diff.changes.question_type">
              <strong>question type:</strong> {{diff.changes.question_type.id_public}}
            </span>
            <br>
            <span [class.red-text]="diff.changes.infraction_type">
              <strong>infraction type:</strong> {{diff.original.infraction_type.id_public}}
            </span>
            <span class="green-text" *ngIf="diff.changes.infraction_type">
              <strong>infraction type:</strong> {{diff.changes.infraction_type.id_public}}
            </span>
            <br>
          </span>
          <p [class.red-text]="diff.changes.question">
            <strong>question:</strong>
            {{diff.original.question}}
          </p>
          <p class="green-text" *ngIf="diff.changes.question">
            <strong>question:</strong>
            {{diff.changes.question}}
          </p>

          <p [class.red-text]="diff.changes.response">
            <strong>response:</strong>
            {{diff.original.response}}
          </p>
          <p class="green-text" *ngIf="diff.changes.response">
            <strong>response:</strong>
            {{diff.changes.response}}
          </p>
          <hr class="clear">
        </div>
        <span [hidden]="changes.changed_diffs.length">no questions edited</span>
      </div>
    </div>
    <span [hidden]="!loadingQuestions">
      loading...
    </span>
  </div>
</div>
<bs-modal #createFormModal (onDismiss)="onQuesticanceled($event)">
  <admin-audit-question-form *ngIf="questionFormActive" [(auditQuestion)]="newQuestion" (saved)="onQuestionCreate($event)" (canceled)="onQuesticanceled($event)" [jurisdictionType]="jurisdiction_type"></admin-audit-question-form>
</bs-modal>
<bs-modal #confirmApplyModal>
  <div class="model-form">
    Are you sure you want to apply the changes for this set?
    <br>
    <button type="button" (click)="applyDraft(); confirmApplyModal.close()">Yes</button>
    <button type="button" (click)="confirmApplyModal.close()">No</button>
  </div>
</bs-modal>
<bs-modal #confirmResetModal>
  <div class="model-form">
    Are you sure you want to reset the changes for this set back to the active set?
    <br>
    <button type="button" (click)="resetDraft(); confirmResetModal.close()">Yes</button>
    <button type="button" (click)="confirmResetModal.close()">No</button>
  </div>
</bs-modal>
