/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./licenses-list.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./licenses.component";
import * as i5 from "../../core/license/license.service";
var styles_AdminLicensesListComponent = [i0.styles];
var RenderType_AdminLicensesListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdminLicensesListComponent, data: {} });
export { RenderType_AdminLicensesListComponent as RenderType_AdminLicensesListComponent };
function View_AdminLicensesListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "a", [["class", "tabular-row"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "tabular-cell name"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "tabular-cell type"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "tabular-cell location"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", ", ", " "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "tabular-cell created"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "]))], function (_ck, _v) { var currVal_2 = i1.ɵinlineInterpolate(1, "/admin/license/", _v.context.$implicit.id, ""); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit.title; _ck(_v, 3, 0, currVal_3); var currVal_4 = (_v.context.$implicit.audit_type ? _v.context.$implicit.audit_type.title : "not set"); _ck(_v, 5, 0, currVal_4); var currVal_5 = _v.context.$implicit.city; var currVal_6 = _v.context.$implicit.state; _ck(_v, 7, 0, currVal_5, currVal_6); var currVal_7 = _v.context.$implicit.created_at; _ck(_v, 9, 0, currVal_7); }); }
export function View_AdminLicensesListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "tabular"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "tabular-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "tabular-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "tabular-head name"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" title "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "tabular-head type"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" type "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "tabular-head state"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" location "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "tabular-head created"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" created "])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "tabular-body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdminLicensesListComponent_1)), i1.ɵdid(13, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.licenses; _ck(_v, 13, 0, currVal_0); }, null); }
export function View_AdminLicensesListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "admin-licenses-list", [], null, null, null, View_AdminLicensesListComponent_0, RenderType_AdminLicensesListComponent)), i1.ɵdid(1, 49152, null, 0, i4.AdminLicensesListComponent, [], null, null)], null, null); }
var AdminLicensesListComponentNgFactory = i1.ɵccf("admin-licenses-list", i4.AdminLicensesListComponent, View_AdminLicensesListComponent_Host_0, { licenses: "licenses" }, {}, []);
export { AdminLicensesListComponentNgFactory as AdminLicensesListComponentNgFactory };
var styles_AdminLicensesComponent = [];
var RenderType_AdminLicensesComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_AdminLicensesComponent, data: {} });
export { RenderType_AdminLicensesComponent as RenderType_AdminLicensesComponent };
export function View_AdminLicensesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "admin-licenses"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "top-bar borderless clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "dashboard-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "admin-licenses-list", [], null, null, null, View_AdminLicensesListComponent_0, RenderType_AdminLicensesListComponent)), i1.ɵdid(5, 49152, null, 0, i4.AdminLicensesListComponent, [], { licenses: [0, "licenses"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.licenses; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_AdminLicensesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "admin-licenses", [], null, null, null, View_AdminLicensesComponent_0, RenderType_AdminLicensesComponent)), i1.ɵdid(1, 114688, null, 0, i4.AdminLicensesComponent, [i5.LicenseService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdminLicensesComponentNgFactory = i1.ɵccf("admin-licenses", i4.AdminLicensesComponent, View_AdminLicensesComponent_Host_0, {}, {}, []);
export { AdminLicensesComponentNgFactory as AdminLicensesComponentNgFactory };
