import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

import { AuditQuestion } from '../../core/audit-question/audit-question';
import { AuditQuestionService } from '../../core/audit-question/audit-question.service';

@Component({
  selector: 'admin-audit-question-form',
  templateUrl: './audit-question-form.component.html',
  // styleUrls:  ['app/admin-panel/auditQuestions/auditQuestion.component.css']
})

export class AdminAuditQuestionFormComponent implements OnInit {
  @Input() auditQuestionId: any;
  @Input() auditQuestion: AuditQuestion = new AuditQuestion();
  @Output() saved = new EventEmitter<AuditQuestion>();
  @Output() canceled = new EventEmitter();
  @Input()
  set jurisdictionType(input: string) {
    this.jurisdiction_type = input;
  }

  jurisdiction_type: string;

  loaders: any = {};

  loading = false;
  saving = false;
  errors: any = {};

  constructor(
    private auditQuestionService: AuditQuestionService
  ) {

  }

  ngOnInit() {
    if (this.auditQuestionId) {
      this.auditQuestionService.getObject(this.auditQuestionId).subscribe(
        auditQuestion => {
          this.auditQuestion = auditQuestion;
          this.loading = false;
          this.initQuestion();
        }
      );
    } else if (this.auditQuestion && this.auditQuestion.id) {
      this.auditQuestionId = this.auditQuestion.id;
    }
    this.initQuestion();
  }

  initQuestion() {
    if (this.jurisdiction_type) {
      // do nothing
    } else if (this.auditQuestion.city_id) {
      this.jurisdiction_type = 'city';
    } else if (this.auditQuestion.county_id) {
        this.jurisdiction_type = 'county';
    } else {
        this.jurisdiction_type = 'state';
    }
  }

  save() {
    console.log('saving');
    this.saving = true;
    this.errors = {};
    if (this.jurisdiction_type === 'state') {
      this.auditQuestion.county = null;
      this.auditQuestion.county_id = null;
      this.auditQuestion.city = null;
      this.auditQuestion.city_id = null;
      this.auditQuestion.is_universal = false;
    } else if (this.jurisdiction_type === 'county') {
      this.auditQuestion.city = null;
      this.auditQuestion.city_id = null;
      this.auditQuestion.state = null;
      this.auditQuestion.is_universal = false;
    } else if (this.jurisdiction_type === 'city') {
      this.auditQuestion.county = null;
      this.auditQuestion.county_id = null;
      this.auditQuestion.state = null;
      this.auditQuestion.is_universal = false;
    } else if (this.jurisdiction_type === 'universal') {
      this.auditQuestion.county = null;
      this.auditQuestion.county_id = null;
      this.auditQuestion.state = null;
      this.auditQuestion.city = null;
      this.auditQuestion.is_universal = true;
    }
    this.auditQuestionService.save(this.auditQuestion).subscribe(
      auditQuestion => {
        this.auditQuestion = auditQuestion;
        this.auditQuestionId = auditQuestion.id;
        this.saving = false;
        this.saved.emit(auditQuestion);
      },
      error => {
        this.errors = error.messages;
        this.saving = false;
      }
    );
  }

  cancel() {
    this.canceled.emit();
  }

  formatText(input) {
    return input.county;
  }

}
