<auto-complete
  [autoCompleteApiService]="autoCompleteApiService"
  (loadingChange)="loading = $event; loadingChange.emit($event)"
  [formatTextFunc]="formatButtonText"
  [(item)]="county"
  (addNewFunc)="createItem($event)"
  (itemChange)="onSelect($event)">
</auto-complete>

<bs-modal #createFormModal>
  <county-form [(county)]="newCounty" (saved)="onCountyCreate($event)" (canceled)="onCountyCreateCancel()"></county-form>
</bs-modal>
