import { ClientUser } from '../client-user/client-user';

export class Client {
  id: number;
  client_name: string;
  state: string;
  address: string;
  address2: string;
  city: string;
  country: string;
  county: string;
  zip: string;
  client_user: ClientUser;
  created_at: Date;
  updated_at: Date;
}
