import { Injectable, Injector } from '@angular/core';
import { ApiResourceService } from '../api-resource.service';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AuditQuestionService extends ApiResourceService {
  public _apiPath = '/audit_questions';
  constructor(private injector: Injector) {
   super(injector);
  }

  getDraftDiff(options?: any): Observable<Object[]> {
    return this._apiService.get('/audit_questions/draft_diff', options).pipe(map((res) => {
      return this.handleCollectionResponse(res);
    }, (error) => {
      return this.handleBasicError(error);
    }));
  }
  applyDraft(options?: any): Observable<Object[]> {
    return this._apiService.post('/audit_questions/apply_draft', null, options).pipe(map((res) => {
      return this.handleCollectionResponse(res);
    }, (error) => {
      return this.handleBasicError(error);
    }));
  }
  resetDraft(options?: any): Observable<Object[]> {
    return this._apiService.post('/audit_questions/reset_draft', null, options).pipe(map((res) => {
      return this.handleCollectionResponse(res);
    }, (error) => {
      return this.handleBasicError(error);
    }));
  }

}
