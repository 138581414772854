<div class="user-form model-form">
  <form (ngSubmit)="save()" #loginForm="ngForm" autocomplete="off">

    <div [hidden]="!errors.server_error" class="alert alert-danger">
      {{errors.server_error}}
    </div>

    <div class="form-group">
      <label for="title">Title</label>
      <input type="text" class="form-control"
          autocomplete="off"
          [(ngModel)]="auditType.title"
          name="email">
      <div [hidden]="!errors.title" class="alert alert-danger">
        {{ errors.email }}
      </div>
    </div>

    <div class="form-group">
      <label>Question Groups</label>
      
    </div>


    <button type="submit" class="btn btn-default">Save</button>
    <button type="button" class="btn btn-alert" (click)="cancel()">Cancel</button>

  </form>
</div>
