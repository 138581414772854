import { Injectable, Injector } from '@angular/core';
import { ApiResourceService } from '../api-resource.service';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class UserService extends ApiResourceService {
  public _apiPath = '/users';

  constructor(private injector: Injector) {
   super(injector);
  }

  getAccessOriginAggregate(options?: any): Observable<Object[]> {
    return this._apiService.get('/user_access_origins/aggregate', options).pipe(map((res) => {
      return this.handleCollectionResponse(res);
    }, (error) => {
      return this.handleBasicError(error);
    }));
  }
  sendPasswordReset(email: string): Observable<Object[]> {
    return this._apiService.post('/users/forgot_password', {email}).pipe(map((res) => {
      return this.handleCollectionResponse(res);
    }, (error) => {
      return this.handleBasicError(error);
    }));
  }
  resetPassword(email, token, newPassword): Observable<Object[]> {
    return this._apiService.post('/users/reset_password', {email, new_password: newPassword, token}).pipe(map((res) => {
      return this.handleCollectionResponse(res);
    }, (error) => {
      return this.handleBasicError(error);
    }));
  }
}
