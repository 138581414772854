import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';

import { Subscription } from 'rxjs/Subscription';

import { QuestionType } from './question-type';
import { QuestionTypeService } from './question-type.service';

import { AutoCompleteBase } from '../ui-helpers/auto-complete/auto-complete-base';

@Component({
  selector: 'question-type-selector',
  templateUrl: './question-type-selector.component.html',
  // styleUrls:  ['app/core/question-type/question-type-selector.component.css']
})
export class QuestionTypeSelectorComponent  {

  @Output() questionTypeChange = new EventEmitter<QuestionType>();
  @Input() questionType: QuestionType;

  @Output() questionTypeIdChange = new EventEmitter<number>();
  @Input()
  set questionTypeId(questionTypeId: number) {
    if (questionTypeId && (!this.questionType || questionTypeId != this.questionType.id)) {
      this.loading = true;
      this.loadingChange.emit(this.loading);
      this.questionTypeService.getObject(questionTypeId).subscribe(
        questionType => {
          this.loading = false;
          this.questionType = questionType;
          this.questionTypeChange.emit(this.questionType);
          this.loadingChange.emit(this.loading);
        }
      );
    }
  }
  get questionTypeId() {
    return this.questionType ? this.questionType.id : null;
  }

  @Input() loading: boolean;
  @Output() loadingChange = new EventEmitter<boolean>();

  public autoCompleteApiService: any;

  constructor(
    private questionTypeService: QuestionTypeService
  ) {
    this.autoCompleteApiService = questionTypeService;
  }
  formatButtonText(item: QuestionType) {
    return item.id_public + ' ' + (item.name ? item.name : '');
  }
  onSelect(model) {
    this.questionType = model;
    this.questionTypeChange.emit(this.questionType);
    this.questionTypeIdChange.emit(this.questionTypeId);
  }
}
