import { Injectable, Injector } from '@angular/core';
import { ApiResourceService } from '../api-resource.service';

@Injectable()
export class CountyService extends ApiResourceService {
  public _apiPath = '/counties';
  constructor(private injector: Injector) {
   super(injector);
  }
}
