import {
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';

import { RouterLink } from '@angular/router';

import { BsModalComponent } from 'ng2-bs3-modal/ng2-bs3-modal';

import { AdminUserFormComponent } from './user-form.component';

import { User } from '../../core/user/user';
import { UserService } from '../../core/user/user.service';

@Component({
  selector: 'admin-users-list',
  templateUrl: './users-list.component.html',
  styleUrls:  ['./users-list.component.less']
})
export class AdminUsersListComponent {
  @Input() users: User[];

  constructor(
  ) { }

}

@Component({
  selector: 'admin-users',
  templateUrl: './users.component.html',
  // styleUrls:  ['app/admin-panel/users/users.component.css']
})
export class AdminUsersComponent implements OnInit {

  users: User[];
  newUser: User = new User();
  @ViewChild('createFormModal', {static: false}) createFormModal: BsModalComponent;

  constructor(
    private userService: UserService
  ) { }

  ngOnInit() {
    this.getUsers();
  }

  getUsers() {
    this.userService.getCollection().subscribe(users => this.users = users);
  }

  addUser() {
    this.createFormModal.open();
  }
  onUserCreate(user: User) {
    this.newUser = new User();
    this.createFormModal.close();
    this.users.unshift(user);
  }
  onUserCreateCancel() {
    this.createFormModal.close();
  }
}
