import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResourceService } from '../api-resource.service';

@Injectable()
export class LicenseService extends ApiResourceService {
  public _apiPath = '/licenses';
  constructor(private injector: Injector) {
   super(injector);
  }
  subscriptionPayment(model: any, options?: any): Observable<any> {
    const request = this._apiService.post(this._apiPath + '/' + model.id + '/subscription', model, options);

    return request.pipe(map((res) => {
      return this.handleObjectResponse(res);
    }, (error) => {
      return this.handleBasicError(error);
    }));
  }
  cancelSubscription(model: any, options?: any): Observable<any> {
    const request = this._apiService.delete(this._apiPath + '/' + model.id + '/subscription', options);

    return request.pipe(map((res) => {
      return this.handleObjectResponse(res);
    }, (error) => {
      return this.handleBasicError(error);
    }));
  }
  questionCount(model: any, options?: any): Observable<any> {
    const request = this._apiService.get(this._apiPath + '/' + model.id + '/question_count', options);

    return request.pipe(map((res) => {
      return this.handleObjectResponse(res);
    }, (error) => {
      return this.handleBasicError(error);
    }));
  }
}
