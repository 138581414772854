import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild
} from '@angular/core';

import { Subscription } from 'rxjs/Subscription';

import { BsModalComponent } from 'ng2-bs3-modal/ng2-bs3-modal';

import { County } from './county';
import { CountyFormComponent } from './county-form.component';
import { CountyService } from './county.service';

import { AutoCompleteBase } from '../ui-helpers/auto-complete/auto-complete-base';

@Component({
  selector: 'county-selector',
  templateUrl: './county-selector.component.html',
  // styleUrls:  ['app/core/county/county-selector.component.css']
})
export class CountySelectorComponent  {

  @Output() countyChange = new EventEmitter<County>();
  @Input() county: County;

  @Output() countyIdChange = new EventEmitter<number>();
  @Input()
  set countyId(countyId: number) {
    if (countyId && (!this.county || countyId !== this.county.id)) {
      console.log(this.county);
      console.log(this);
      this.loading = true;
      this.loadingChange.emit(this.loading);
      this.countyService.getObject(countyId).subscribe(
        county => {
          this.loading = false;
          this.county = county;
          this.countyChange.emit(this.county);
          this.loadingChange.emit(this.loading);
        }
      );
    }
  }
  get countyId() {
    return this.county ? this.county.id : null;
  }

  @Input() loading: boolean;
  @Output() loadingChange = new EventEmitter<boolean>();

  newCounty: County = new County();
  @ViewChild('createFormModal', {static: false}) createFormModal: BsModalComponent;

  public autoCompleteApiService: any;

  constructor(
    private countyService: CountyService
  ) {
    this.autoCompleteApiService = countyService;
  }
  formatButtonText(item: County) {
    return item.county + ', ' + item.state;
  }
  onSelect(model) {
    this.county = model;
    this.countyChange.emit(this.county);
    this.countyIdChange.emit(this.countyId);
  }

  createItem(str) {
    const parts = str.split(',');
    this.newCounty.county = parts[0] ? parts[0].replace(/^\s\s*/, '').replace(/\s\s*$/, '') : '';
    this.newCounty.state = parts[1] ? parts[1].replace(/^\s\s*/, '').replace(/\s\s*$/, '') : '';
    this.createFormModal.open();
  }
  onCountyCreate(county: County) {
    this.newCounty = new County();
    this.createFormModal.close();
    this.onSelect(county);
  }
  onCountyCreateCancel() {
    this.createFormModal.close();
  }
}
