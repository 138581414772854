import { Injectable, Injector } from '@angular/core';
import { InfractionType } from './infraction-type';

import { Observable } from 'rxjs';
import { Observer } from 'rxjs/Observer';

import { ApiResourceService } from '../api-resource.service';

@Injectable()
export class InfractionTypeService extends ApiResourceService {
  protected _apiPath = '/infraction_types';

  protected _cachedCollection: InfractionType[];
  protected _cachedSubscribe: Observable<InfractionType[]>;

  constructor(private injector: Injector) {
   super(injector);
  }

  protected softConstructor(
  ) {
    this.getCachedCollection().subscribe(types => this._cachedCollection = types);
  }

  getCachedCollection(options?: any): Observable<InfractionType[]> {
    if (this._cachedCollection) {
      let observer: Observer<InfractionType[]>;
      setTimeout(() => observer.next(this._cachedCollection));
      return new Observable(setObserver => observer = setObserver)
        .share() as Observable<InfractionType[]>;
    } else if (this._cachedSubscribe) {
      return this._cachedSubscribe;
    } else {
      this._cachedSubscribe = this.getCollection(options);
      return this._cachedSubscribe;
    }
  }

}
