/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./admin-panel.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./admin-panel.component";
import * as i5 from "../core/audit-type/audit-type.service";
import * as i6 from "../core/auth/auth.service";
var styles_AdminPanelComponent = [i0.styles];
var RenderType_AdminPanelComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdminPanelComponent, data: {} });
export { RenderType_AdminPanelComponent as RenderType_AdminPanelComponent };
function View_AdminPanelComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 48, "ul", [["class", "dashboard-nav-menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "a", [["routerLink", "/admin/dashboard"], ["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, [[2, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(4, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(-1, null, [" Dashboard "])), (_l()(), i1.ɵeld(8, 0, null, null, 6, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 5, "a", [["routerLink", "/admin/users"], ["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 671744, [[4, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(11, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 3, { links: 1 }), i1.ɵqud(603979776, 4, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(-1, null, [" Users "])), (_l()(), i1.ɵeld(15, 0, null, null, 6, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 5, "a", [["routerLink", "/admin/clients"], ["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(17, 671744, [[6, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(18, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 5, { links: 1 }), i1.ɵqud(603979776, 6, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(-1, null, [" Clients "])), (_l()(), i1.ɵeld(22, 0, null, null, 6, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 5, "a", [["routerLink", "/admin/licenses"], ["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 24).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(24, 671744, [[8, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(25, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 7, { links: 1 }), i1.ɵqud(603979776, 8, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(-1, null, [" Licenses "])), (_l()(), i1.ɵeld(29, 0, null, null, 6, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(30, 0, null, null, 5, "a", [["routerLink", "/admin/audit-types"], ["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 31).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(31, 671744, [[10, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(32, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 9, { links: 1 }), i1.ɵqud(603979776, 10, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(-1, null, [" Audit Types "])), (_l()(), i1.ɵeld(36, 0, null, null, 6, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(37, 0, null, null, 5, "a", [["routerLink", "/admin/audit-questions"], ["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 38).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(38, 671744, [[12, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(39, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 11, { links: 1 }), i1.ɵqud(603979776, 12, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(-1, null, [" Audit Questions "])), (_l()(), i1.ɵeld(43, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(44, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(45, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(46, 0, null, null, 2, "a", [["routerLink", "/logout"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 47).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(47, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, [" Logout "]))], function (_ck, _v) { var currVal_2 = "/admin/dashboard"; _ck(_v, 3, 0, currVal_2); var currVal_3 = "active"; _ck(_v, 4, 0, currVal_3); var currVal_6 = "/admin/users"; _ck(_v, 10, 0, currVal_6); var currVal_7 = "active"; _ck(_v, 11, 0, currVal_7); var currVal_10 = "/admin/clients"; _ck(_v, 17, 0, currVal_10); var currVal_11 = "active"; _ck(_v, 18, 0, currVal_11); var currVal_14 = "/admin/licenses"; _ck(_v, 24, 0, currVal_14); var currVal_15 = "active"; _ck(_v, 25, 0, currVal_15); var currVal_18 = "/admin/audit-types"; _ck(_v, 31, 0, currVal_18); var currVal_19 = "active"; _ck(_v, 32, 0, currVal_19); var currVal_22 = "/admin/audit-questions"; _ck(_v, 38, 0, currVal_22); var currVal_23 = "active"; _ck(_v, 39, 0, currVal_23); var currVal_26 = "/logout"; _ck(_v, 47, 0, currVal_26); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_4 = i1.ɵnov(_v, 10).target; var currVal_5 = i1.ɵnov(_v, 10).href; _ck(_v, 9, 0, currVal_4, currVal_5); var currVal_8 = i1.ɵnov(_v, 17).target; var currVal_9 = i1.ɵnov(_v, 17).href; _ck(_v, 16, 0, currVal_8, currVal_9); var currVal_12 = i1.ɵnov(_v, 24).target; var currVal_13 = i1.ɵnov(_v, 24).href; _ck(_v, 23, 0, currVal_12, currVal_13); var currVal_16 = i1.ɵnov(_v, 31).target; var currVal_17 = i1.ɵnov(_v, 31).href; _ck(_v, 30, 0, currVal_16, currVal_17); var currVal_20 = i1.ɵnov(_v, 38).target; var currVal_21 = i1.ɵnov(_v, 38).href; _ck(_v, 37, 0, currVal_20, currVal_21); var currVal_24 = i1.ɵnov(_v, 47).target; var currVal_25 = i1.ɵnov(_v, 47).href; _ck(_v, 46, 0, currVal_24, currVal_25); }); }
export function View_AdminPanelComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "admin-panel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "content-body clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdminPanelComponent_1)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "dashboard-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(6, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentUser; _ck(_v, 3, 0, currVal_0); _ck(_v, 6, 0); }, null); }
export function View_AdminPanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "admin-panel", [], null, null, null, View_AdminPanelComponent_0, RenderType_AdminPanelComponent)), i1.ɵdid(1, 114688, null, 0, i4.AdminPanelComponent, [i2.Router, i5.AuditTypeService, i6.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdminPanelComponentNgFactory = i1.ɵccf("admin-panel", i4.AdminPanelComponent, View_AdminPanelComponent_Host_0, {}, {}, []);
export { AdminPanelComponentNgFactory as AdminPanelComponentNgFactory };
