<div class="tabular">
  <div class="tabular-header">
    <div class="tabular-row">
      <span class="tabular-head name">
        client name
      </span>
      <span class="tabular-head state">
        state
      </span>
      <span class="tabular-head created">
        created
      </span>
    </div>
  </div>
  <div class="tabular-body">
    <a *ngFor="let client of clients"
      routerLink="/admin/client/{{client.id}}"
      class="tabular-row">
      <span class="tabular-cell name">
        {{client.client_name}}
      </span>
      <span class="tabular-cell state">
        {{client.state}}
      </span>
      <span class="tabular-cell created">
        {{client.created_at}}
      </span>
    </a>
  </div>
</div>
