<div *ngIf="user" class="admin-user">
  <div class="top-bar clearfix">
    <div class="actions">
      <button (click)="editUser()" type="button">
        edit
      </button>
    </div>
    <h1>{{user.email}}</h1>
  </div>

  <div class="dashboard-content">
    <div class="info clearfix">
      <div class="col-xs-6">
        <div><strong>name:</strong> {{user.name || 'not set'}}</div>
      </div>
      <div class="col-xs-6">
        <div><strong>phone:</strong> {{user.phone || 'not set'}}</div>
      </div>
      <div class="col-xs-6">
        <div><strong>active:</strong> {{user.is_active ? 'yes' : 'no'}}</div>
      </div>
      <div class="col-xs-6">
        <div><strong>admin:</strong> {{user.is_admin ? 'yes' : 'no'}}</div>
      </div>
      <div class="col-xs-6">
        <div><strong>docusign exempt:</strong> {{user.docusign_exempt ? 'yes' : 'no'}}</div>
      </div>
      <div class="col-xs-6">
        <div><strong>docusign complete:</strong> {{user.docusign_complete ? 'yes' : 'no'}}</div>
      </div>
      <div class="col-xs-6">
        <div><strong>braintree customer id:</strong> {{user.braintree_customer_id || 'not set'}}</div>
      </div>
    </div>
    <h3>Clients</h3>
    <div class="actions">
      <button (click)="connectClientModal.open()" type="button">
        + add client
      </button>
    </div>
    <div class="tabular">
      <div class="tabular-header">
        <div class="tabular-row">
          <span class="tabular-head name">
            client name
          </span>
          <span class="tabular-head state">
            state
          </span>
          <span class="tabular-head role">
            role
          </span>
          <span class="tabular-head controls">
            actions
          </span>
        </div>
      </div>
      <div class="tabular-body">
        <span class="tabular-row" *ngFor="let client of clients; let i = index">
          <span class="tabular-cell name">
            {{client.client_name}}
          </span>
          <span class="tabular-cell state">
            {{client.state}}
          </span>
          <span class="tabular-cell role">
            {{client.client_user.role}}
          </span>
          <span class="tabular-cell created">
            <button (click)="removeClientUser(i)">unlink</button>
            <a routerLink="/admin/client/{{client.id}}">
              view</a>
          </span>
        </span>
      </div>
    </div>


    <h3>Access Origin</h3>

    <div class="tabular">
      <div class="tabular-header">
        <div class="tabular-row">
          <span class="tabular-head name">
            ip address
          </span>
          <span class="tabular-head state">
            logins
          </span>
          <span class="tabular-head role">
            total api calls
          </span>
          <span class="tabular-head controls">
            first used
          </span>
          <span class="tabular-head controls">
            last used
          </span>
        </div>
      </div>
      <div class="tabular-body">
        <span class="tabular-row" *ngFor="let access of accessAggregate; let i = index">
          <span class="tabular-cell name">
            {{access.ip_address}}
          </span>
          <span class="tabular-cell state">
            {{access.logins}}
          </span>
          <span class="tabular-cell role">
            {{access.total_uses}}
          </span>
          <span class="tabular-cell controls">
            {{access.created_at}}
          </span>
          <span class="tabular-cell controls">
            {{access.last_used}}
          </span>
        </span>
      </div>
    </div>

  </div>

  <bs-modal #editFormModal>
    <admin-user-form [user]="userEdit" (saved)="onUserSave($event)" (canceled)="editFormModal.close()"></admin-user-form>
  </bs-modal>
</div>
<div *ngIf="!user" class="top-bar">
  <span [hidden]="!userIsLoading">loading...</span>
  <span *ngFor="let error of userLoadErrors | mapToIterable">{{ error.val }} <br></span>
</div>

<bs-modal #connectClientModal>
  <client-user-form [clientUser]="newClientUser" (saved)="onClientUserSave()" (canceled)="connectClientModal.close()" ></client-user-form>
</bs-modal>
