import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

import { Client } from '../../core/client/client';
import { ClientService } from '../../core/client/client.service';

@Component({
  selector: 'admin-client-form',
  templateUrl: './client-form.component.html',
  // styleUrls:  ['./client.component.css'],
})

export class AdminClientFormComponent implements OnInit {
  @Input() clientId: any;
  @Input() client: Client = new Client();
  @Output() saved = new EventEmitter<Client>();
  @Output() canceled = new EventEmitter();

  loading: any = {};
  saving = false;
  errors: any = {};

  constructor(
    private clientService: ClientService
  ) {

  }

  ngOnInit() {
    if (this.clientId) {
      this.clientService.getObject(this.clientId).subscribe(
        client => {
          this.client = client;
          this.loading = false;
        }
      );
    } else if (this.client && this.client.id) {
      this.clientId = this.client.id;
    }
  }

  save() {
    this.saving = true;
    this.errors = {};
    this.clientService.save(this.client).subscribe(
      client => {
        this.client = client;
        this.clientId = client.id;
        this.saving = false;
        this.saved.emit(client);
      },
      error => {
        this.errors = error.messages;
        this.saving = false;
      }
    );
  }

  cancel() {
    this.canceled.emit();
  }

}
