/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "../client/client-selector.component.ngfactory";
import * as i3 from "../client/client-selector.component";
import * as i4 from "../client/client.service";
import * as i5 from "./client-user.service";
import * as i6 from "./client-user-form.component";
var styles_ClientUserFormComponent = [];
var RenderType_ClientUserFormComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ClientUserFormComponent, data: {} });
export { RenderType_ClientUserFormComponent as RenderType_ClientUserFormComponent };
export function View_ClientUserFormComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 29, "div", [["class", "client-user-form model-form"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 28, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngSubmit"], [null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 3).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("ngSubmit" === en)) {
        var pd_2 = (_co.save() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 16384, null, 0, i1.ɵangular_packages_forms_forms_z, [], null, null), i0.ɵdid(3, 4210688, [["loginForm", 4]], 0, i1.NgForm, [[8, null], [8, null]], null, { ngSubmit: "ngSubmit" }), i0.ɵprd(2048, null, i1.ControlContainer, null, [i1.NgForm]), i0.ɵdid(5, 16384, null, 0, i1.NgControlStatusGroup, [[4, i1.ControlContainer]], null, null), (_l()(), i0.ɵeld(6, 0, null, null, 1, "div", [["class", "alert alert-danger"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵted(7, null, [" ", " "])), (_l()(), i0.ɵeld(8, 0, null, null, 6, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "label", [["for", "client_id"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Client"])), (_l()(), i0.ɵeld(11, 0, null, null, 1, "client-selector", [], null, [[null, "clientIdChange"], [null, "clientChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clientIdChange" === en)) {
        var pd_0 = ((_co.clientUser.client_id = $event) !== false);
        ad = (pd_0 && ad);
    } if (("clientChange" === en)) {
        var pd_1 = (_co.changeClient($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_ClientSelectorComponent_0, i2.RenderType_ClientSelectorComponent)), i0.ɵdid(12, 49152, null, 0, i3.ClientSelectorComponent, [i4.ClientService], { clientId: [0, "clientId"] }, { clientChange: "clientChange", clientIdChange: "clientIdChange" }), (_l()(), i0.ɵeld(13, 0, null, null, 1, "div", [["class", "alert alert-danger"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵted(14, null, [" ", " "])), (_l()(), i0.ɵeld(15, 0, null, null, 10, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 1, "label", [["for", "role"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Role"])), (_l()(), i0.ɵeld(18, 0, null, null, 5, "input", [["class", "form-control"], ["name", "role"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 19)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 19).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 19)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 19)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.clientUser.role = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i0.ɵdid(19, 16384, null, 0, i1.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i1.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i1.DefaultValueAccessor]), i0.ɵdid(21, 671744, null, 0, i1.NgModel, [[2, i1.ControlContainer], [8, null], [8, null], [6, i1.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i1.NgControl, null, [i1.NgModel]), i0.ɵdid(23, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null), (_l()(), i0.ɵeld(24, 0, null, null, 1, "div", [["class", "alert alert-danger"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵted(25, null, [" ", " "])), (_l()(), i0.ɵeld(26, 0, null, null, 1, "button", [["class", "btn btn-default"], ["type", "submit"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Save"])), (_l()(), i0.ɵeld(28, 0, null, null, 1, "button", [["class", "btn btn-alert"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Cancel"]))], function (_ck, _v) { var _co = _v.component; var currVal_9 = _co.clientUser.client_id; _ck(_v, 12, 0, currVal_9); var currVal_19 = "role"; var currVal_20 = _co.clientUser.role; _ck(_v, 21, 0, currVal_19, currVal_20); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 5).ngClassValid; var currVal_5 = i0.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 5).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = !_co.errors.server_error; _ck(_v, 6, 0, currVal_7); var currVal_8 = _co.errors.server_error; _ck(_v, 7, 0, currVal_8); var currVal_10 = !_co.errors.client; _ck(_v, 13, 0, currVal_10); var currVal_11 = _co.errors.client; _ck(_v, 14, 0, currVal_11); var currVal_12 = i0.ɵnov(_v, 23).ngClassUntouched; var currVal_13 = i0.ɵnov(_v, 23).ngClassTouched; var currVal_14 = i0.ɵnov(_v, 23).ngClassPristine; var currVal_15 = i0.ɵnov(_v, 23).ngClassDirty; var currVal_16 = i0.ɵnov(_v, 23).ngClassValid; var currVal_17 = i0.ɵnov(_v, 23).ngClassInvalid; var currVal_18 = i0.ɵnov(_v, 23).ngClassPending; _ck(_v, 18, 0, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17, currVal_18); var currVal_21 = !_co.errors.email; _ck(_v, 24, 0, currVal_21); var currVal_22 = _co.errors.email; _ck(_v, 25, 0, currVal_22); }); }
export function View_ClientUserFormComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "client-user-form", [], null, null, null, View_ClientUserFormComponent_0, RenderType_ClientUserFormComponent)), i0.ɵprd(512, null, i5.ClientUserService, i5.ClientUserService, [i0.Injector]), i0.ɵdid(2, 114688, null, 0, i6.ClientUserFormComponent, [i5.ClientUserService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var ClientUserFormComponentNgFactory = i0.ɵccf("client-user-form", i6.ClientUserFormComponent, View_ClientUserFormComponent_Host_0, { clientUserId: "clientUserId", clientUser: "clientUser" }, { saved: "saved", canceled: "canceled" }, []);
export { ClientUserFormComponentNgFactory as ClientUserFormComponentNgFactory };
