import { Injectable, Injector } from '@angular/core';
import { AuditType } from './audit-type';

import { Observable, Observer } from 'rxjs';

import { ApiResourceService } from '../api-resource.service';

@Injectable()
export class AuditTypeService extends ApiResourceService {
  protected _apiPath = '/audit_types';

  protected _cachedCollection: AuditType[];
  protected _cachedSubscribe: Observable<AuditType[]>;

  constructor(private injector: Injector) {
   super(injector);
  }

  protected softConstructor(
  ) {
    this.getCachedCollection().subscribe(types => this._cachedCollection = types);
  }

  getCachedCollection(options?: any): Observable<AuditType[]> {
    if (this._cachedCollection) {
      let observer: Observer<AuditType[]>;
      setTimeout(() => observer.next(this._cachedCollection));
      return new Observable(setObserver => observer = setObserver);
    } else if (this._cachedSubscribe) {
      return this._cachedSubscribe;
    } else {
      this._cachedSubscribe = this.getCollection(options);
      return this._cachedSubscribe;
    }
  }

}
