import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';

import { Subscription } from 'rxjs/Subscription';

import { InfractionType } from './infraction-type';
import { InfractionTypeService } from './infraction-type.service';

import { AutoCompleteBase } from '../ui-helpers/auto-complete/auto-complete-base';

@Component({
  selector: 'infraction-type-selector',
  templateUrl: './infraction-type-selector.component.html',
  // styleUrls:  ['app/core/infraction-type/infraction-type-selector.component.css']
})
export class InfractionTypeSelectorComponent  {

  @Output() infractionTypeChange = new EventEmitter<InfractionType>();
  @Input() infractionType: InfractionType;

  @Output() infractionTypeIdChange = new EventEmitter<number>();
  @Input()
  set infractionTypeId(infractionTypeId: number) {
    if (infractionTypeId && (!this.infractionType || infractionTypeId != this.infractionType.id)) {
      console.log(this.infractionType);
      console.log(this);
      this.loading = true;
      this.loadingChange.emit(this.loading);
      this.infractionTypeService.getObject(infractionTypeId).subscribe(
        infractionType => {
          this.loading = false;
          this.infractionType = infractionType;
          this.infractionTypeChange.emit(this.infractionType);
          this.loadingChange.emit(this.loading);
        }
      );
    }
  }
  get infractionTypeId() {
    return this.infractionType ? this.infractionType.id : null;
  }

  @Input() loading: boolean;
  @Output() loadingChange = new EventEmitter<boolean>();

  public autoCompleteApiService: any;

  constructor(
    private infractionTypeService: InfractionTypeService
  ) {
    this.autoCompleteApiService = infractionTypeService;
  }
  formatButtonText(item: InfractionType) {
    return item.id_public + ' ' + (item.name ? item.name : '');
  }
  onSelect(model) {
    this.infractionType = model;
    this.infractionTypeChange.emit(this.infractionType);
    this.infractionTypeIdChange.emit(this.infractionTypeId);
  }
}
