import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

import { County } from './county';
import { CountyService } from './county.service';

@Component({
  selector: 'county-form',
  templateUrl: './county-form.component.html',
  // styleUrls:  ['app/core/county/county-form.component.css']
})

export class CountyFormComponent implements OnInit {
  @Input() countyId: any;
  @Input() county: County = new County();
  @Output() saved = new EventEmitter<County>();
  @Output() canceled = new EventEmitter();

  loading = false;
  saving = false;
  errors: any = {};

  constructor(
    private countyService: CountyService
  ) {

  }

  ngOnInit() {
    if (this.countyId) {
      this.countyService.getObject(this.countyId).subscribe(
        county => {
          this.county = county;
          this.loading = false;
        }
      );
    } else if (this.county && this.county.id) {
      this.countyId = this.county.id;
    }
  }

  save() {
    this.saving = true;
    this.errors = {};
    this.countyService.save(this.county).subscribe(
      county => {
        this.county = county;
        this.countyId = county.id;
        this.saving = false;
        this.saved.emit(county);
      },
      error => {
        this.errors = error.messages;
        this.saving = false;
      }
    );
  }

  cancel() {
    this.canceled.emit(null);
  }

}
