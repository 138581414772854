import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

import { City } from './city';
import { CityService } from './city.service';

@Component({
  selector: 'city-form',
  templateUrl: './city-form.component.html',
  // styleUrls:  ['app/core/city/city-form.component.css']
})

export class CityFormComponent implements OnInit {
  @Input() cityId: any;
  @Input() city: City = new City();
  @Output() saved = new EventEmitter<City>();
  @Output() canceled = new EventEmitter();

  loading = false;
  saving = false;
  errors: any = {};

  constructor(
    private cityService: CityService
  ) {

  }

  ngOnInit() {
    if (this.cityId) {
      this.cityService.getObject(this.cityId).subscribe(
        city => {
          this.city = city;
          this.loading = false;
        }
      );
    } else if (this.city && this.city.id) {
      this.cityId = this.city.id;
    }
  }

  save() {
    this.saving = true;
    this.errors = {};
    this.cityService.save(this.city).subscribe(
      city => {
        this.city = city;
        this.cityId = city.id;
        this.saving = false;
        this.saved.emit(city);
      },
      error => {
        this.errors = error.messages;
        this.saving = false;
      }
    );
  }

  cancel() {
    this.canceled.emit(null);
  }

}
