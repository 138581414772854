
<input [(ngModel)]="typedIn"
    name="typedIn"
    placeholder="start typing and select from list"
    class="form-control"
    (keyup)="inputKeyUp($event)"
    (keydown)="inputKeyDown($event)"
    (focus)="isFocused = true; setupAndDoSearch()"
    (blur)="isFocused = false"
    #inputElem
    >
<div class="auto-complete-container">
  <div *ngIf="autoCompleteLoading==true" class="auto-complete-results">
    <div class="auto-complete-loading">
      loading
    </div>
  </div>
  <div *ngIf="autoCompleteLoading!=true">
    <div [hidden]="!showDrop || !isFocused" class="auto-complete-results" #resultComponent>
      <button *ngFor="let entry of autoCompleteResults; let i = index"
          [class.active]="focusedIndex == i"
          (mouseup)="applySelection(i)"
          (mousedown)="clickingOption = true; inputElem.focus(); clickingOption = false"
          (mouseover)="focusedIndex = i"
          type="button">
        {{formatButtonText(entry)}}
      </button>
      <button *ngIf="addNewFuncIndex != null"
          [class.active]="focusedIndex == addNewFuncIndex"
          (mouseup)="applySelection(addNewFuncIndex)"
          (mousedown)="clickingOption = true; inputElem.focus(); clickingOption = false"
          (mouseover)="focusedIndex = addNewFuncIndex"
          type="button">
        add new
      </button>
      <div *ngIf="autoCompleteNoResults" class="no-results">
        No results
      </div>
    </div>
    <div *ngIf="autoCompleteNoResults==true && !addNewFunc" class="" style="" class="auto-complete-results">
        No Results Found
    </div>
  </div>
</div>
