<div class="admin-users">
  <div class="top-bar borderless clearfix">
  </div>

  <div class="dashboard-content">
    <div class="actions">
      <button (click)="addUser()" type="button">
        + new user
      </button>
    </div>
    <admin-users-list [users]="users"></admin-users-list>
  </div>
</div>

<bs-modal #createFormModal>
  <admin-user-form [(user)]="newUser" (saved)="onUserCreate($event)" (canceled)="onUserCreateCancel()"></admin-user-form>
</bs-modal>
