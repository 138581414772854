import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

import { Client } from '../client/client';
import { ClientUser } from './client-user';
import { ClientUserService } from './client-user.service';

import { ClientSelectorComponent } from '../client/client-selector.component';

@Component({
  selector: 'client-user-form',
  templateUrl: './client-user-form.component.html',
  // styleUrls:  ['app/admin-panel/users/user.component.css'],
  providers: [ClientUserService]
})
export class ClientUserFormComponent implements OnInit {
  @Input() clientUserId: any;
  @Input() clientUser: ClientUser;
  @Output() saved = new EventEmitter<ClientUser>();
  @Output() canceled = new EventEmitter();
  loading = false;
  saving = false;
  errors: any = {};

  client: Client;

  constructor(
    private clientUserService: ClientUserService
  ) {

  }

  ngOnInit() {
    if (this.clientUserId) {
      this.clientUserService.getObject(this.clientUserId).subscribe(
        clientUser => {
          this.clientUser = clientUser;
          this.loading = false;
        }
      );
    } else if (this.clientUser && this.clientUser.id) {
      this.clientUserId = this.clientUser.id;
    } else if (!this.clientUser) {
      this.clientUser = new ClientUser;
    }
  }

  changeClient(event) {
    console.log(this.clientUser);
    this.client = event as Client;
    if (this.client) {
      this.clientUser.client_id = this.client.id;
    } else {
      this.clientUser.client_id = null;
    }
  }

  save() {
    this.saving = true;
    this.errors = {};
    console.log(this.clientUser);
    this.clientUserService.save(this.clientUser).subscribe(
      clientUser => {
        this.clientUser = clientUser;
        this.clientUserId = clientUser.id;
        this.saving = false;
        this.saved.emit(clientUser);
      },
      error => {
        this.errors = error.messages;
        this.saving = false;
      }
    );
  }

  cancel() {
    this.canceled.emit(null);
  }

}
