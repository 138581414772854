import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';

import { Subscription } from 'rxjs/Subscription';

import { Client } from './client';
import { ClientService } from './client.service';

import { AutoCompleteBase } from '../ui-helpers/auto-complete/auto-complete-base';

@Component({
  selector: 'client-selector',
  templateUrl: './client-selector.component.html',
  // styleUrls:  ['app/core/client/client-selector.component.css']
})
export class ClientSelectorComponent  {

  @Output() clientChange = new EventEmitter<Client>();
  @Input() client: Client;

  @Output() clientIdChange = new EventEmitter<number>();
  @Input()
  set clientId(clientId: number) {
    if (clientId && (!this.client || clientId != this.client.id)) {
      console.log(this.client);
      console.log(this);
      this.loading = true;
      this.loadingChange.emit(this.loading);
      this.clientService.getObject(clientId).subscribe(
        client => {
          this.loading = false;
          this.client = client;
          this.clientChange.emit(this.client);
          this.loadingChange.emit(this.loading);
        }
      );
    }
  }
  get clientId() {
    return this.client ? this.client.id : null;
  }

  @Input() loading: boolean;
  @Output() loadingChange = new EventEmitter<boolean>();

  public autoCompleteApiService: any;

  constructor(
    private clientService: ClientService
  ) {
    this.autoCompleteApiService = clientService;
  }
  formatButtonText(item: Client) {
    return item.client_name;
  }
  onSelect(model) {
    this.client = model;
    this.clientChange.emit(this.client);
    this.clientIdChange.emit(this.clientId);
  }
}
