<div class="client-user-form model-form">
  <form (ngSubmit)="save()" #loginForm="ngForm">

    <div [hidden]="!errors.server_error" class="alert alert-danger">
      {{errors.server_error}}
    </div>

    <div class="form-group">
      <label for="client_id">Client</label>
      <client-selector [(clientId)]="clientUser.client_id" (clientChange)="changeClient($event)"></client-selector>
      <div [hidden]="!errors.client" class="alert alert-danger">
        {{ errors.client }}
      </div>
    </div>

    <div class="form-group">
      <label for="role">Role</label>
      <input type="text" class="form-control"
          [(ngModel)]="clientUser.role"
          name="role">
      <div [hidden]="!errors.email" class="alert alert-danger">
        {{ errors.email }}
      </div>
    </div>

    <button type="submit" class="btn btn-default">Save</button>
    <button type="button" class="btn btn-alert" (click)="cancel()">Cancel</button>

  </form>
</div>
