<div *ngFor="let question of auditQuestions; let index = index"
  class="question-row relative clearfix">
  <div class="loading" [hidden]="!removing[question.id]">
    removing
  </div>

  <h3>{{question.id_public}}</h3>
  <span class="detail-info inner-shadow-soft">
    <span *ngIf="question.edit_draft">
      <button (click)="editQuestion(index)">edit</button>
      <button (click)="removeQuestion(index)">remove</button>
      <br>
    </span>
    <span class="tabular-cell jurisdiction">
      <span *ngIf="question.state">
        <strong>state:</strong> {{question.state}}
      </span>
      <span *ngIf="question.county">
        <strong>county:</strong> {{question.county.county}}, {{question.county.state}}
      </span>
      <span *ngIf="question.city">
        <strong>city:</strong> {{question.city.city}}, {{question.city.state}}
      </span>
    </span>
    <br>
    <strong>series:</strong> {{question.series.id_public}}
    <br>
    <strong>question type:</strong> {{question.question_type.id_public}}
    <br>
    <strong>infraction type:</strong> {{question.infraction_type.id_public}}
    <br>
  </span>
  <p>
    <strong>question:</strong>
    {{question.question}}
  </p>

  <p>
    <strong>response:</strong>
    {{question.response}}
  </p>
  <hr class="clear">
</div>

<bs-modal #editFormModal (onHide)="onQuestionEditCancel()">
  <div *ngIf="editingQuestion">
    <admin-audit-question-form [auditQuestion]="editingQuestion" (saved)="onQuestionEdit($event)" (canceled)="editFormModal.close()"></admin-audit-question-form>
  </div>
</bs-modal>
