<div class="tabular">
  <div class="tabular-header">
    <div class="tabular-row">
      <span class="tabular-head name">
        title
      </span>
      <span class="tabular-head type">
        type
      </span>
      <span class="tabular-head state">
        location
      </span>
      <span class="tabular-head created">
        created
      </span>
    </div>
  </div>
  <div class="tabular-body">
    <a *ngFor="let license of licenses"
      routerLink="/admin/license/{{license.id}}"
      class="tabular-row">
      <span class="tabular-cell name">
        {{license.title}}
      </span>
      <span class="tabular-cell type">
        {{license.audit_type ? license.audit_type.title : 'not set'}}
      </span>
      <span class="tabular-cell location">
        {{license.city}}, {{license.state}}
      </span>
      <span class="tabular-cell created">
        {{license.created_at}}
      </span>
    </a>
  </div>
</div>
