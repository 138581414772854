import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../../core/auth/auth.service';

@Component({
  selector: 'admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls:  ['./admin-login.component.less']
})
export class AdminLoginComponent implements OnInit {
  creds = {email: '', password: ''};
  errors: any = {};
  constructor(
    private authService: AuthService
  ) { }

  ngOnInit() {

  }

  onSubmit() {
    this.errors = {};
    this.authService.login(this.creds.email, this.creds.password)
      .subscribe(
        user => this.handleLogin(user),
        errors => this.handleLoginError(errors)
      );
  }

  handleLogin(user) {

  }

  handleLoginError(errors) {
    this.errors = errors;
  }
}

@Component({
  selector: 'admin-logout',
  templateUrl: './admin-logout.component.html',
  styleUrls:  ['./admin-login.component.less']
})
export class AdminLogoutComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    this.authService.logout();
    setTimeout(() => {
      this.router.navigate(['/']);
    }, 3000);
  }
}
