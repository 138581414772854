export class User {
  id: number;
  email: string;
  name: string;
  phone: string;
  admin_disabled = false;
  email_verified: boolean;
  password: string;
  is_admin: boolean;
  is_active = true;
  created_at: Date;
  updated_at: Date;

  docusign_sent: boolean;
  docusign_complete: boolean;
  docusign_envelope_ids: string[];
  docusign_exempt: boolean;

  braintree_customer_id: string;
}
