import {
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';

import { RouterLink } from '@angular/router';

import { BsModalComponent } from 'ng2-bs3-modal/ng2-bs3-modal';

import { AdminAuditTypeFormComponent } from './audit-type-form.component';

import { AuditType } from '../../core/audit-type/audit-type';
import { AuditTypeService } from '../../core/audit-type/audit-type.service';

@Component({
  selector: 'admin-audit-types-list',
  templateUrl: './audit-types-list.component.html',
  styleUrls:  ['./audit-types-list.component.less']
})
export class AdminAuditTypesListComponent {
  @Input() auditTypes: AuditType[];

  constructor(
  ) { }

}

@Component({
  selector: 'admin-audit-types',
  templateUrl: './audit-types.component.html',
})
export class AdminAuditTypesComponent implements OnInit {

  auditTypes: AuditType[];
  newAuditType: AuditType = new AuditType();
  @ViewChild('createFormModal', {static: false}) createFormModal: BsModalComponent;

  constructor(
    private auditTypeService: AuditTypeService
  ) { }

  ngOnInit() {
    this.getAuditTypes();
  }

  getAuditTypes() {
    this.auditTypeService.getCollection().subscribe(auditTypes => this.auditTypes = auditTypes);
  }

  addAuditType() {
    this.createFormModal.open();
  }
  onAuditTypeCreate(auditType: AuditType) {
    this.newAuditType = new AuditType();
    this.createFormModal.close();
    this.auditTypes.unshift(auditType);
  }
  onAuditTypeCreateCancel() {
    this.createFormModal.close();
  }
}
