<div class="license-form model-form">
  <form (ngSubmit)="save()" #loginForm="ngForm">

    <div [hidden]="!errors.server_error" class="alert alert-danger">
      {{errors.server_error}}
    </div>

    <div class="form-group">
      <label for="titleIn">Title</label>
      <input type="text" class="form-control"
          [(ngModel)]="license.title"
          name="title"
          id="titleIn">
      <div [hidden]="!errors.title" class="alert alert-danger">
        {{ errors.title }}
      </div>
    </div>

    <div class="form-group">
      <label for="license_numberIn">License Number</label>
      <input type="text" class="form-control"
          [(ngModel)]="license.license_number"
          name="license_number"
          id="license_numberIn">
      <div [hidden]="!errors.license_number" class="alert alert-danger">
        {{ errors.license_number }}
      </div>
    </div>

    <div class="form-group">
      <label for="audit_type_idIn">Audit Type Id</label>

      <select class="form-control"
        [(ngModel)]="license.audit_type_id"
        name="audit_type_id"
        id="audit_type_idIn">
          <option *ngFor="let type of auditTypes" [value]="type.id">{{type.title}}</option>
      </select>

      <div [hidden]="!errors.audit_type_id" class="alert alert-danger">
        {{ errors.audit_type_id }}
      </div>
      <div [hidden]="!errors.audit_type" class="alert alert-danger">
        {{ errors.audit_type }}
      </div>
    </div>

    <div class="form-group">
      <label for="addressIn">Address 1</label>
      <input type="text" class="form-control"
          [(ngModel)]="license.address"
          name="address"
          id="addressIn">
      <div [hidden]="!errors.address" class="alert alert-danger">
        {{ errors.address }}
      </div>
    </div>

    <div class="form-group">
      <label for="address2In">Address 2</label>
      <input type="text" class="form-control"
          [(ngModel)]="license.address2"
          name="address2"
          id="address2In">
      <div [hidden]="!errors.address2" class="alert alert-danger">
        {{ errors.address2 }}
      </div>
    </div>

    <div class="form-group">
      <label for="cityIn">City</label>
      <input type="text" class="form-control"
          [(ngModel)]="license.city"
          name="city"
          id="cityIn">
      <div [hidden]="!errors.city" class="alert alert-danger">
        {{ errors.city }}
      </div>
    </div>

    <div class="form-group">
      <label for="countyIn">County</label>
      <input type="text" class="form-control"
          [(ngModel)]="license.county"
          name="county"
          id="countyIn">
      <div [hidden]="!errors.county" class="alert alert-danger">
        {{ errors.county }}
      </div>
    </div>

    <div class="form-group">
      <label for="stateIn">State (code)</label>
      <input type="text" class="form-control"
          [(ngModel)]="license.state"
          name="state"
          id="stateIn">
      <div [hidden]="!errors.state" class="alert alert-danger">
        {{ errors.state }}
      </div>
    </div>

    <div class="form-group">
      <label for="countryIn">Country (code)</label>
      <input type="text" class="form-control"
          [(ngModel)]="license.country"
          name="country"
          id="countryIn">
      <div [hidden]="!errors.country" class="alert alert-danger">
        {{ errors.country }}
      </div>
    </div>

    <div class="form-group">
      <label for="zipIn">Zip Code</label>
      <input type="text" class="form-control"
          [(ngModel)]="license.zip"
          name="zip"
          id="zipIn">
      <div [hidden]="!errors.zip" class="alert alert-danger">
        {{ errors.zip }}
      </div>
    </div>

    <div class="form-group">
      <label for="subscription_exemptIn">Subscription Exempt</label>
      <input type="checkbox" class=""
          [(ngModel)]="license.subscription_exempt"
          name="subscription_exempt"
          id="subscription_exemptIn">
    </div>

    <button type="submit" class="btn btn-default">Save</button>
    <button type="button" class="btn btn-alert" (click)="cancel()">Cancel</button>

  </form>
</div>
