<div class="tabular">
  <div class="tabular-header">
    <div class="tabular-row">
      <span class="tabular-head email">
        title
      </span>
      <span class="tabular-head created">
        created
      </span>
    </div>
  </div>
  <div class="tabular-body">
    <a *ngFor="let auditType of auditTypes"
      routerLink="/admin/auditType/{{auditType.id}}"
      class="tabular-row">
      <span class="tabular-cell title">
        {{auditType.title}}
      </span>
      <span class="tabular-cell created">
        {{auditType.created_at}}
      </span>
    </a>
  </div>
</div>
