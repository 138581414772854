import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';


@Injectable()
export class ApiService {
  constructor(private http: HttpClient) {}

  private apiBaseUrl = '/api/v2';
  private headers = { 'Content-Type': 'application/json', Accept: 'application/json' };
  private requestOptions = {
    headers: this.headers,
  };
  private requestOptionsForGet = {
    headers: this.headers,
  };

  get(url: string, options?): Observable<any> {
    if (options) {
      options = {
        ...options,
        ...this.requestOptionsForGet
      };
    } else {
      options = this.requestOptionsForGet;
    }
    const requestUrl = this.apiBaseUrl + url;
    return this.http.get(requestUrl, options).pipe(map((res) => {
      return res;
    }), catchError((error) => {
      return this.handleBasicError(error);
    }));
  }

  post(url: string, body?: any, options?): Observable<any> {
    if (options) {
      options = {
        ...options,
        ...this.requestOptions
      };
    } else {
      options = this.requestOptions;
    }
    const requestUrl = this.apiBaseUrl + url;
    const requestBody = JSON.stringify(body);
    return this.http.post(requestUrl, requestBody, options).pipe(map((res) => {
      return res;
    }), catchError((error) => {
      return this.handleBasicError(error);
    }));
  }

  patch(url: string, body?: any, options?): Observable<any> {
    if (options) {
      options = {
        ...options,
        ...this.requestOptions
      };
    } else {
      options = this.requestOptions;
    }
    const requestUrl = this.apiBaseUrl + url;
    const requestBody = JSON.stringify(body);
    return this.http.patch(requestUrl, requestBody, options).pipe(map((res) => {
      return res;
    }), catchError((error) => {
      return this.handleBasicError(error);
    }));
  }

  delete(url: string, options?): Observable<any> {
    if (options) {
      options = {
        ...options,
        ...this.requestOptionsForGet
      };
    } else {
      options = this.requestOptionsForGet;
    }
    const requestUrl = this.apiBaseUrl + url;
    return this.http.delete(requestUrl, options).pipe(map((res) => {
      return res;
    }), catchError((error) => {
      return this.handleBasicError(error);
    }));
  }

  addAuthHeaders(email: string, token: string) {
    this.headers['X-User-Email'] =  email;
    this.headers['X-User-Killable-Token'] =  token;
  }

  removeAuthHeaders() {
    delete(this.headers['X-User-Email']);
    delete(this.headers['X-User-Killable-Token']);
  }

  private handleBasicError(error) {
    error.messages = {};
    try {
      if (error.error) {
        error.messages = error.error;
      }
      if (error.status === 404) {
        error.messages[404] = 'record not found';
      }
      if (error.status === 500) {
        error.messages.server_error = 'server error';
      }
    } catch (e) {
      error.messages.server_error = 'server error';
    }
    return throwError(error);
  }

}
