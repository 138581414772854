<div class="client-form model-form">
  <form (ngSubmit)="save()" #loginForm="ngForm">

    <div [hidden]="!errors.server_error" class="alert alert-danger">
      {{errors.server_error}}
    </div>

    <div class="form-group">
      <label for="client_name">Name</label>
      <input type="text" class="form-control"
          [(ngModel)]="client.client_name"
          name="client_name">
      <div [hidden]="!errors.client_name" class="alert alert-danger">
        {{ errors.client_name }}
      </div>
    </div>

    <div class="form-group">
      <label for="address">Address 1</label>
      <input type="text" class="form-control"
          [(ngModel)]="client.address"
          name="address">
      <div [hidden]="!errors.address" class="alert alert-danger">
        {{ errors.address }}
      </div>
    </div>

    <div class="form-group">
      <label for="address2">Address 2</label>
      <input type="text" class="form-control"
          [(ngModel)]="client.address2"
          name="address2">
      <div [hidden]="!errors.address2" class="alert alert-danger">
        {{ errors.address2 }}
      </div>
    </div>

    <div class="form-group">
      <label for="city">City</label>
      <input type="text" class="form-control"
          [(ngModel)]="client.city"
          name="city">
      <div [hidden]="!errors.city" class="alert alert-danger">
        {{ errors.city }}
      </div>
    </div>

    <div class="form-group">
      <label for="county">County</label>
      <input type="text" class="form-control"
          [(ngModel)]="client.county"
          name="county">
      <div [hidden]="!errors.county" class="alert alert-danger">
        {{ errors.county }}
      </div>
    </div>

    <div class="form-group">
      <label for="state">State (code)</label>
      <input type="text" class="form-control"
          [(ngModel)]="client.state"
          name="state">
      <div [hidden]="!errors.state" class="alert alert-danger">
        {{ errors.state }}
      </div>
    </div>

    <div class="form-group">
      <label for="country">Country (code)</label>
      <input type="text" class="form-control"
          [(ngModel)]="client.country"
          name="country">
      <div [hidden]="!errors.country" class="alert alert-danger">
        {{ errors.country }}
      </div>
    </div>

    <div class="form-group">
      <label for="zip">Zip Code</label>
      <input type="text" class="form-control"
          [(ngModel)]="client.zip"
          name="zip">
      <div [hidden]="!errors.zip" class="alert alert-danger">
        {{ errors.zip }}
      </div>
    </div>

    <button type="submit" class="btn btn-default">Save</button>
    <button type="button" class="btn btn-alert" (click)="cancel()">Cancel</button>

  </form>
</div>
