<div class="tabular">
  <div class="tabular-header">
    <div class="tabular-row">
      <span class="tabular-head email">
        email
      </span>
      <span class="tabular-head admin">
        admin
      </span>
      <span class="tabular-head is_active">
        active
      </span>
      <span class="tabular-head created">
        created
      </span>
    </div>
  </div>
  <div class="tabular-body">
    <a *ngFor="let user of users"
      routerLink="/admin/user/{{user.id}}"
      class="tabular-row">
      <span class="tabular-cell email">
        {{user.email}}
      </span>
      <span class="tabular-cell admin">
        {{user.is_admin ? 'admin' : ''}}
      </span>
      <span class="tabular-cell is_active">
        {{user.is_active ? '&#10003;' : ''}}
      </span>
      <span class="tabular-cell created">
        {{user.created_at}}
      </span>
    </a>
  </div>
</div>
