import { Injectable, Injector } from '@angular/core';
import { Series } from './series';

import { Observable } from 'rxjs';
import { Observer } from 'rxjs/Observer';

import { ApiResourceService } from '../api-resource.service';

@Injectable()
export class SeriesService extends ApiResourceService {
  protected _apiPath = '/series';

  protected _cachedCollection: Series[];
  protected _cachedSubscribe: Observable<Series[]>;

  constructor(private injector: Injector) {
   super(injector);
  }

  protected softConstructor(
  ) {
    this.getCachedCollection().subscribe(types => this._cachedCollection = types);
  }

  getCachedCollection(options?: any): Observable<Series[]> {
    if (this._cachedCollection) {
      let observer: Observer<Series[]>;
      setTimeout(() => observer.next(this._cachedCollection));
      return new Observable(setObserver => observer = setObserver)
        .share() as Observable<Series[]>;
    } else if (this._cachedSubscribe) {
      return this._cachedSubscribe;
    } else {
      this._cachedSubscribe = this.getCollection(options);
      return this._cachedSubscribe;
    }
  }

}
