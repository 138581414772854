<div class="admin-login">
  <form (ngSubmit)="onSubmit()" #loginForm="ngForm">

    <div [hidden]="!errors.login_incorrect" class="alert alert-danger">
      {{errors.login_incorrect}}
    </div>
    <div [hidden]="!errors.server_error" class="alert alert-danger">
      {{errors.server_error}}
    </div>

    <div class="form-group">
      <label for="email">Email</label>
      <input type="text" class="form-control" required
          [(ngModel)]="creds.email"
          name="email"
          #email="ngModel" >
      <div [hidden]="email.valid || email.pristine" class="alert alert-danger">
        Enter email
      </div>
    </div>

    <div class="form-group">
      <label for="password">Password</label>
      <input type="password" class="form-control" required
          [(ngModel)]="creds.password"
          name="password"
          #password="ngModel" >
      <div [hidden]="password.valid || password.pristine" class="alert alert-danger">
        Enter password
      </div>
    </div>

    <button type="submit" class="btn btn-default" [disabled]="!loginForm.form.valid">Login</button>

  </form>
</div>
