import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from './core/auth/auth.service';
import { User } from './core/user/user';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls:  ['./app-header.component.less']
})
export class AppHeaderComponent implements OnInit {
  currentUser: User;

  constructor(
    private router: Router,
    private authService: AuthService) { }

  ngOnInit() {

  }
}
