import {
  Component,
  OnInit,
  ViewChild
} from '@angular/core';

import { ActivatedRoute } from '@angular/router';

import { BsModalComponent } from 'ng2-bs3-modal/ng2-bs3-modal';

import { cloneDeep } from 'lodash';

import { License } from '../../core/license/license';
import { LicenseService } from '../../core/license/license.service';

import { MapToIterable } from '../../pipes/map-to-iterable.pipe';

@Component({
  selector: 'admin-license',
  templateUrl: './license.component.html',
  // styleUrls:  ['app/admin-panel/licenses/license.component.css']
})
export class AdminLicenseComponent implements OnInit {

  license: License;
  licenseEdit: License = new License();
  licenseIsLoading: boolean;
  licenseLoadErrors = {};
  @ViewChild('editFormModal', {static: false}) editFormModal: BsModalComponent;

  constructor(
    private route: ActivatedRoute,
    private licenseService: LicenseService
  ) { }

  ngOnInit() {
    const id = this.route.snapshot.params.id;
    if (id === 'new') {
      this.license = new License();
    } else {
      this.loadLicense(id);
    }
  }

  loadLicense(id) {
    this.licenseIsLoading = true;
    this.licenseService.getObject(id).subscribe(
      license => {
        this.license = license;
        this.licenseIsLoading = false;
      },
      error => {
        console.log(error);
        this.licenseLoadErrors = error.messages;
        this.licenseIsLoading = false;
      }
    );
  }

  onLicenseSave(license) {
    this.license = license;
    this.editFormModal.close();
  }

  editLicense() {
    this.licenseEdit = cloneDeep(this.license);
    this.editFormModal.open();
  }
}
