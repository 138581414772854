import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';

import { Subscription } from 'rxjs/Subscription';

import { Series } from './series';
import { SeriesService } from './series.service';

import { AutoCompleteBase } from '../ui-helpers/auto-complete/auto-complete-base';

@Component({
  selector: 'series-selector',
  templateUrl: './series-selector.component.html',
  // styleUrls:  ['app/core/series/series-selector.component.css']
})
export class SeriesSelectorComponent  {

  @Output() seriesChange = new EventEmitter<Series>();
  @Input() series: Series;

  @Output() seriesIdChange = new EventEmitter<number>();
  @Input()
  set seriesId(seriesId: number) {
    if (seriesId && (!this.series || seriesId != this.series.id)) {
      console.log(this.series);
      console.log(this);
      this.loading = true;
      this.loadingChange.emit(this.loading);
      this.seriesService.getObject(seriesId).subscribe(
        series => {
          this.loading = false;
          this.series = series;
          this.seriesChange.emit(this.series);
          this.loadingChange.emit(this.loading);
        }
      );
    }
  }
  get seriesId() {
    return this.series ? this.series.id : null;
  }

  @Input() loading: boolean;
  @Output() loadingChange = new EventEmitter<boolean>();

  public autoCompleteApiService: any;

  constructor(
    private seriesService: SeriesService
  ) {
    this.autoCompleteApiService = seriesService;
  }
  formatButtonText(item: Series) {
    return item.id_public + ' ' + (item.name ? item.name : '');
  }
  onSelect(model) {
    this.series = model;
    this.seriesChange.emit(this.series);
    this.seriesIdChange.emit(this.seriesId);
  }
}
