import { City } from '../city/city';
import { County } from '../county/county';
import { InfractionType } from '../infraction-type/infraction-type';
import { QuestionType } from '../question-type/question-type';
import { Series } from '../series/series';

export class AuditQuestion {
  id: number;
  id_public: string;
  series_id: number;
  series: Series;
  question_type_id: number;
  question_type: QuestionType;
  infraction_type_id: number;
  infraction_type: InfractionType;
  question: string;
  response: string;
  city_id: number;
  city: City;
  county_id: number;
  county: County;
  state: string;
  country: string;
  is_universal: boolean;
  edit_draft: boolean;
  activated_on: Date;
  deactivated_on: Date;
  created_at: Date;
  updated_at: Date;
}
