<div class="admin-audit-types">
  <div class="top-bar borderless clearfix">
  </div>

  <div class="dashboard-content">
    <div class="actions">
      <button (click)="addAuditType()" type="button">
        + new audit type
      </button>
    </div>
    <admin-audit-types-list [auditTypes]="auditTypes"></admin-audit-types-list>
  </div>
</div>

<bs-modal #createFormModal>
  <admin-audit-type-form *ngIf="newAuditType" [(auditType)]="newAuditType" (saved)="onAuditTypeCreate($event)" (canceled)="onAuditTypeCreateCancel()"></admin-audit-type-form>
</bs-modal>
