import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild
} from '@angular/core';

import { Subscription } from 'rxjs/Subscription';

import { BsModalComponent } from 'ng2-bs3-modal/ng2-bs3-modal';

import { City } from './city';
import { CityFormComponent } from './city-form.component';
import { CityService } from './city.service';

import { AutoCompleteBase } from '../ui-helpers/auto-complete/auto-complete-base';

@Component({
  selector: 'city-selector',
  templateUrl: './city-selector.component.html',
  // styleUrls:  ['app/core/city/city-selector.component.css']
})
export class CitySelectorComponent  {

  @Output() cityChange = new EventEmitter<City>();
  @Input() city: City;

  @Output() cityIdChange = new EventEmitter<number>();
  @Input()
  set cityId(cityId: number) {
    if (cityId && (!this.city || cityId !== this.city.id)) {
      this.loading = true;
      this.loadingChange.emit(this.loading);
      this.cityService.getObject(cityId).subscribe(
        city => {
          this.loading = false;
          this.city = city;
          this.cityChange.emit(this.city);
          this.loadingChange.emit(this.loading);
        }
      );
    }
  }
  get cityId() {
    return this.city ? this.city.id : null;
  }

  @Input() loading: boolean;
  @Output() loadingChange = new EventEmitter<boolean>();

  newCity: City = new City();
  @ViewChild('createFormModal', {static: false}) createFormModal: BsModalComponent;

  public autoCompleteApiService: any;

  constructor(
    private cityService: CityService
  ) {
    this.autoCompleteApiService = cityService;
  }
  formatButtonText(item: City) {
    return item.city + ', ' + item.state;
  }
  onSelect(model) {
    this.city = model;
    this.cityChange.emit(this.city);
    this.cityIdChange.emit(this.cityId);
  }

  createItem(str) {
    const parts = str.split(',');
    this.newCity.city = parts[0] ? parts[0].replace(/^\s\s*/, '').replace(/\s\s*$/, '') : '';
    this.newCity.state = parts[1] ? parts[1].replace(/^\s\s*/, '').replace(/\s\s*$/, '') : '';
    this.createFormModal.open();
  }
  onCityCreate(city: City) {
    this.newCity = new City();
    this.createFormModal.close();
    this.onSelect(city);
  }
  onCityCreateCancel() {
    this.createFormModal.close();
  }
}
