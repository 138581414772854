/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../ui-helpers/auto-complete/auto-complete-base.ngfactory";
import * as i2 from "../ui-helpers/auto-complete/auto-complete-base";
import * as i3 from "../../../../node_modules/ng2-bs3-modal/modal/modal.ngfactory";
import * as i4 from "ng2-bs3-modal/modal/modal";
import * as i5 from "ng2-bs3-modal/modal/modal-service";
import * as i6 from "./county-form.component.ngfactory";
import * as i7 from "./county-form.component";
import * as i8 from "./county.service";
import * as i9 from "./county-selector.component";
var styles_CountySelectorComponent = [];
var RenderType_CountySelectorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CountySelectorComponent, data: {} });
export { RenderType_CountySelectorComponent as RenderType_CountySelectorComponent };
export function View_CountySelectorComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { createFormModal: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 1, "auto-complete", [], null, [[null, "loadingChange"], [null, "itemChange"], [null, "addNewFunc"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("loadingChange" === en)) {
        _co.loading = $event;
        var pd_0 = (_co.loadingChange.emit($event) !== false);
        ad = (pd_0 && ad);
    } if (("itemChange" === en)) {
        var pd_1 = ((_co.county = $event) !== false);
        ad = (pd_1 && ad);
    } if (("addNewFunc" === en)) {
        var pd_2 = (_co.createItem($event) !== false);
        ad = (pd_2 && ad);
    } if (("itemChange" === en)) {
        var pd_3 = (_co.onSelect($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i1.View_AutoCompleteBase_0, i1.RenderType_AutoCompleteBase)), i0.ɵdid(2, 573440, null, 0, i2.AutoCompleteBase, [], { autoCompleteApiService: [0, "autoCompleteApiService"], item: [1, "item"], formatTextFunc: [2, "formatTextFunc"] }, { itemChange: "itemChange", addNewFunc: "addNewFunc", loadingChange: "loadingChange" }), (_l()(), i0.ɵeld(3, 0, null, null, 3, "bs-modal", [], [[2, "fade", null], [2, "modal", null], [1, "role", 0], [1, "tabindex", 0]], null, null, i3.View_BsModalComponent_0, i3.RenderType_BsModalComponent)), i0.ɵdid(4, 4964352, [[1, 4], ["createFormModal", 4]], 0, i4.BsModalComponent, [i0.ElementRef, i5.BsModalService, i0.NgZone], null, null), (_l()(), i0.ɵeld(5, 0, null, 0, 1, "county-form", [], null, [[null, "countyChange"], [null, "saved"], [null, "canceled"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("countyChange" === en)) {
        var pd_0 = ((_co.newCounty = $event) !== false);
        ad = (pd_0 && ad);
    } if (("saved" === en)) {
        var pd_1 = (_co.onCountyCreate($event) !== false);
        ad = (pd_1 && ad);
    } if (("canceled" === en)) {
        var pd_2 = (_co.onCountyCreateCancel() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i6.View_CountyFormComponent_0, i6.RenderType_CountyFormComponent)), i0.ɵdid(6, 114688, null, 0, i7.CountyFormComponent, [i8.CountyService], { county: [0, "county"] }, { saved: "saved", canceled: "canceled" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.autoCompleteApiService; var currVal_1 = _co.county; var currVal_2 = _co.formatButtonText; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); _ck(_v, 4, 0); var currVal_7 = _co.newCounty; _ck(_v, 6, 0, currVal_7); }, function (_ck, _v) { var currVal_3 = i0.ɵnov(_v, 4).fadeClass; var currVal_4 = i0.ɵnov(_v, 4).modalClass; var currVal_5 = i0.ɵnov(_v, 4).roleAttr; var currVal_6 = i0.ɵnov(_v, 4).tabindexAttr; _ck(_v, 3, 0, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_CountySelectorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "county-selector", [], null, null, null, View_CountySelectorComponent_0, RenderType_CountySelectorComponent)), i0.ɵdid(1, 49152, null, 0, i9.CountySelectorComponent, [i8.CountyService], null, null)], null, null); }
var CountySelectorComponentNgFactory = i0.ɵccf("county-selector", i9.CountySelectorComponent, View_CountySelectorComponent_Host_0, { county: "county", countyId: "countyId", loading: "loading" }, { countyChange: "countyChange", countyIdChange: "countyIdChange", loadingChange: "loadingChange" }, []);
export { CountySelectorComponentNgFactory as CountySelectorComponentNgFactory };
