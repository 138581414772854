import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

import { User } from '../../core/user/user';
import { UserService } from '../../core/user/user.service';

@Component({
  selector: 'admin-user-form',
  templateUrl: './user-form.component.html',
  // styleUrls:  ['app/admin-panel/users/user.component.css']
})
export class AdminUserFormComponent implements OnInit {
  @Input() userId: any;
  @Input() user: User;
  @Output() saved = new EventEmitter<User>();
  @Output() canceled = new EventEmitter();
  loading = false;
  saving = false;
  errors: any = {};

  constructor(
    private userService: UserService
  ) {

  }

  ngOnInit() {
    if (this.userId) {
      this.userService.getObject(this.userId).subscribe(
        user => {
          this.user = user;
          this.loading = false;
        }
      );
    } else if (this.user && this.user.id) {
      this.userId = this.user.id;
    }
  }

  save() {
    this.saving = true;
    this.errors = {};
    this.userService.save(this.user).subscribe(
      user => {
        this.user = user;
        this.userId = user.id;
        this.saving = false;
        this.saved.emit(user);
      },
      error => {
        this.errors = error.messages;
        this.saving = false;
      }
    );
  }

  cancel() {
    this.canceled.emit();
  }

}
