import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

import { AuditType } from '../../core/audit-type/audit-type';
import { AuditTypeService } from '../../core/audit-type/audit-type.service';

@Component({
  selector: 'admin-audit-type-form',
  templateUrl: './audit-type-form.component.html'
})
export class AdminAuditTypeFormComponent implements OnInit {
  @Input() auditTypeId: any;
  @Input() auditType: AuditType;
  @Output() saved = new EventEmitter<AuditType>();
  @Output() canceled = new EventEmitter();
  loading = false;
  questionTypeLoading = false;
  saving = false;
  errors: any = {};

  constructor(
    private auditTypeService: AuditTypeService
  ) {

  }

  ngOnInit() {
    if (this.auditTypeId) {
      this.auditTypeService.getObject(this.auditTypeId).subscribe(
        auditType => {
          this.auditType = auditType;
          this.loading = false;
        }
      );
    } else if (this.auditType && this.auditType.id) {
      this.auditTypeId = this.auditType.id;
    }
  }

  save() {
    this.saving = true;
    this.errors = {};
    this.auditTypeService.save(this.auditType).subscribe(
      auditType => {
        this.auditType = auditType;
        this.auditTypeId = auditType.id;
        this.saving = false;
        this.saved.emit(auditType);
      },
      error => {
        this.errors = error.messages;
        this.saving = false;
      }
    );
  }

  cancel() {
    this.canceled.emit();
  }

}
