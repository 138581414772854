import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

import { AuditType } from '../../core/audit-type/audit-type';
import { AuditTypeService } from '../../core/audit-type/audit-type.service';

import { License } from '../../core/license/license';
import { LicenseService } from '../../core/license/license.service';

@Component({
  selector: 'admin-license-form',
  templateUrl: './license-form.component.html',
  // styleUrls:  ['app/admin-panel/licenses/license.component.css']
})
export class AdminLicenseFormComponent implements OnInit {
  @Input() licenseId: any;
  @Input() license: License;
  @Output() saved = new EventEmitter<License>();
  @Output() canceled = new EventEmitter();
  loading = false;
  saving = false;
  errors: any = {};
  auditTypes: AuditType[];

  constructor(
    private licenseService: LicenseService,
    private auditTypeService: AuditTypeService
  ) {
    auditTypeService.getCollection().subscribe(types => {
      this.auditTypes = types;
    });
  }

  ngOnInit() {
    if (this.licenseId) {
      this.licenseService.getObject(this.licenseId).subscribe(
        license => {
          this.license = license;
          this.loading = false;
        }
      );
    } else if (this.license && this.license.id) {
      this.licenseId = this.license.id;
    }
  }

  selectAuditType(e) {

  }

  save() {
    this.saving = true;
    this.errors = {};
    this.licenseService.save(this.license).subscribe(
      license => {
        this.license = license;
        this.licenseId = license.id;
        this.saving = false;
        this.saved.emit(license);
      },
      error => {
        this.errors = error.messages;
        this.saving = false;
      }
    );
  }

  cancel() {
    this.canceled.emit(null);
  }

}
