/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../licenses/licenses.component.ngfactory";
import * as i2 from "../licenses/licenses.component";
import * as i3 from "../../../../node_modules/ng2-bs3-modal/modal/modal.ngfactory";
import * as i4 from "ng2-bs3-modal/modal/modal";
import * as i5 from "ng2-bs3-modal/modal/modal-service";
import * as i6 from "./client-form.component.ngfactory";
import * as i7 from "./client-form.component";
import * as i8 from "../../core/client/client.service";
import * as i9 from "../licenses/license-form.component.ngfactory";
import * as i10 from "../licenses/license-form.component";
import * as i11 from "../../core/license/license.service";
import * as i12 from "../../core/audit-type/audit-type.service";
import * as i13 from "@angular/common";
import * as i14 from "../../pipes/map-to-iterable.pipe";
import * as i15 from "./client.component";
import * as i16 from "@angular/router";
var styles_AdminClientComponent = [];
var RenderType_AdminClientComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AdminClientComponent, data: {} });
export { RenderType_AdminClientComponent as RenderType_AdminClientComponent };
function View_AdminClientComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 39, "div", [["class", "admin-client"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "top-bar clearfix"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "button", [["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.editClient() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" edit "])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), (_l()(), i0.ɵeld(7, 0, null, null, 24, "div", [["class", "dashboard-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 15, "div", [["class", "info clearfix"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 14, "div", [["class", "location-cont"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Location"])), (_l()(), i0.ɵeld(12, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(13, null, ["", ""])), (_l()(), i0.ɵeld(14, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(15, null, ["", ""])), (_l()(), i0.ɵeld(16, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(17, null, ["city: ", ""])), (_l()(), i0.ɵeld(18, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(19, null, ["county: ", ""])), (_l()(), i0.ɵeld(20, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(21, null, ["state: ", ""])), (_l()(), i0.ɵeld(22, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(23, null, ["zip: ", ""])), (_l()(), i0.ɵeld(24, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(25, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Licenses"])), (_l()(), i0.ɵeld(27, 0, null, null, 2, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i0.ɵeld(28, 0, null, null, 1, "button", [["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 37).open() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" + new license "])), (_l()(), i0.ɵeld(30, 0, null, null, 1, "admin-licenses-list", [], null, null, null, i1.View_AdminLicensesListComponent_0, i1.RenderType_AdminLicensesListComponent)), i0.ɵdid(31, 49152, null, 0, i2.AdminLicensesListComponent, [], { licenses: [0, "licenses"] }, null), (_l()(), i0.ɵeld(32, 0, null, null, 3, "bs-modal", [], [[2, "fade", null], [2, "modal", null], [1, "role", 0], [1, "tabindex", 0]], null, null, i3.View_BsModalComponent_0, i3.RenderType_BsModalComponent)), i0.ɵdid(33, 4964352, [[1, 4], ["editFormModal", 4]], 0, i4.BsModalComponent, [i0.ElementRef, i5.BsModalService, i0.NgZone], null, null), (_l()(), i0.ɵeld(34, 0, null, 0, 1, "admin-client-form", [], null, [[null, "saved"], [null, "canceled"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("saved" === en)) {
        var pd_0 = (_co.onClientSave($event) !== false);
        ad = (pd_0 && ad);
    } if (("canceled" === en)) {
        var pd_1 = (i0.ɵnov(_v, 33).close() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_AdminClientFormComponent_0, i6.RenderType_AdminClientFormComponent)), i0.ɵdid(35, 114688, null, 0, i7.AdminClientFormComponent, [i8.ClientService], { client: [0, "client"] }, { saved: "saved", canceled: "canceled" }), (_l()(), i0.ɵeld(36, 0, null, null, 3, "bs-modal", [], [[2, "fade", null], [2, "modal", null], [1, "role", 0], [1, "tabindex", 0]], null, null, i3.View_BsModalComponent_0, i3.RenderType_BsModalComponent)), i0.ɵdid(37, 4964352, [[2, 4], ["addLicenseModal", 4]], 0, i4.BsModalComponent, [i0.ElementRef, i5.BsModalService, i0.NgZone], null, null), (_l()(), i0.ɵeld(38, 0, null, 0, 1, "admin-license-form", [], null, [[null, "saved"], [null, "canceled"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("saved" === en)) {
        var pd_0 = (_co.onLicenseSave($event) !== false);
        ad = (pd_0 && ad);
    } if (("canceled" === en)) {
        var pd_1 = (i0.ɵnov(_v, 37).close() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i9.View_AdminLicenseFormComponent_0, i9.RenderType_AdminLicenseFormComponent)), i0.ɵdid(39, 114688, null, 0, i10.AdminLicenseFormComponent, [i11.LicenseService, i12.AuditTypeService], { license: [0, "license"] }, { saved: "saved", canceled: "canceled" })], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.licenses; _ck(_v, 31, 0, currVal_7); _ck(_v, 33, 0); var currVal_12 = _co.clientEdit; _ck(_v, 35, 0, currVal_12); _ck(_v, 37, 0); var currVal_17 = _co.newLicense; _ck(_v, 39, 0, currVal_17); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.client.client_name; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.client.address; _ck(_v, 13, 0, currVal_1); var currVal_2 = _co.client.address2; _ck(_v, 15, 0, currVal_2); var currVal_3 = _co.client.city; _ck(_v, 17, 0, currVal_3); var currVal_4 = _co.client.county; _ck(_v, 19, 0, currVal_4); var currVal_5 = _co.client.state; _ck(_v, 21, 0, currVal_5); var currVal_6 = _co.client.zip; _ck(_v, 23, 0, currVal_6); var currVal_8 = i0.ɵnov(_v, 33).fadeClass; var currVal_9 = i0.ɵnov(_v, 33).modalClass; var currVal_10 = i0.ɵnov(_v, 33).roleAttr; var currVal_11 = i0.ɵnov(_v, 33).tabindexAttr; _ck(_v, 32, 0, currVal_8, currVal_9, currVal_10, currVal_11); var currVal_13 = i0.ɵnov(_v, 37).fadeClass; var currVal_14 = i0.ɵnov(_v, 37).modalClass; var currVal_15 = i0.ɵnov(_v, 37).roleAttr; var currVal_16 = i0.ɵnov(_v, 37).tabindexAttr; _ck(_v, 36, 0, currVal_13, currVal_14, currVal_15, currVal_16); }); }
function View_AdminClientComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", " "])), (_l()(), i0.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.val; _ck(_v, 1, 0, currVal_0); }); }
function View_AdminClientComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "top-bar"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["loading..."])), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_AdminClientComponent_3)), i0.ɵdid(4, 278528, null, 0, i13.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵppd(5, 1)], function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i0.ɵnov(_v.parent, 0), _co.clientLoadErrors)); _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.clientIsLoading; _ck(_v, 1, 0, currVal_0); }); }
export function View_AdminClientComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i14.MapToIterable, []), i0.ɵqud(671088640, 1, { editFormModal: 0 }), i0.ɵqud(671088640, 2, { addLicenseModal: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AdminClientComponent_1)), i0.ɵdid(4, 16384, null, 0, i13.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AdminClientComponent_2)), i0.ɵdid(6, 16384, null, 0, i13.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.client; _ck(_v, 4, 0, currVal_0); var currVal_1 = !_co.client; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_AdminClientComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "admin-client", [], null, null, null, View_AdminClientComponent_0, RenderType_AdminClientComponent)), i0.ɵdid(1, 114688, null, 0, i15.AdminClientComponent, [i16.ActivatedRoute, i8.ClientService, i11.LicenseService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdminClientComponentNgFactory = i0.ɵccf("admin-client", i15.AdminClientComponent, View_AdminClientComponent_Host_0, {}, {}, []);
export { AdminClientComponentNgFactory as AdminClientComponentNgFactory };
